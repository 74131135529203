<template>
    <com-left class="route-left-box"
              ref="left"
              :left-scroll-top="leftScrollTop"
              :top-height="0"
              :bottom-height="0">
        <div>
            <div v-show="!isShowTripDriver && !isShowTripVehicle && !isShowCollision && !isShowTripFleet"
                 class="rlb-no-data-box">{{$t('globalText.loading')}}
            </div>
            <trip-list v-show="isShowTripDriver"
                       :start-date="startDate"
                       :end-date="endDate"
                       :trip-summary="tripSummary"
                       :loading-status="loadingStatus"
                       :trip-list="currentTripDriverListT"
                       :show-parking-alert="false"
                       @filter-trip-flag-rules="handleFilterTripFlagRules"
                       @changeTrip="handleTripDriverSelected"/>
            <trip-list v-show="isShowTripVehicle"
                       :start-date="startDate"
                       :end-date="endDate"
                       :trip-summary="tripSummary"
                       :loading-status="loadingStatus"
                       :trip-list="currentTripVehicleList"
                       :show-parking-alert="isShowParkingAlert"
                       @filter-trip-flag-rules="handleFilterTripFlagRules"
                       @changeCollision="handleTripCollisionChangeFromVehicleList"
                       @changeTrip="handleTripVehicleSelected"/>
            <trip-list v-show="isShowTripFleet"
                       :start-date="startDate"
                       :end-date="endDate"
                       :trip-summary="tripSummary"
                       :loading-status="loadingStatus"
                       :trip-list="currentTripFleetList"
                       :show-parking-alert="!isShowParkingAlert"
                       @filter-trip-flag-rules="handleFilterTripFlagRules"
                       @changeCollision="handleTripCollisionChangeFromVehicleList"
                       @changeTrip="handleTripFleetSelected"/>
            <alerts-list v-show="isShowCollision"
                         :start-date="startDate"
                         :end-date="endDate"
                         :show-alert-icon="alertCategory === 'All'"
                         :loading-status="loadingStatus"
                         :route-event-id="routeId"
                         :collision-list="tripEventList"
                         :vehicle-list="apiVehicleList"
                         :new-alert="eventForAlerts"
                         @scrollTop="handleScrollTop"
                         @changeCollision="handleTripCollisionChange"/>
        </div>
    </com-left>
</template>

<script>
    import ComLeft from '../../components/monitor/ComLeft';
    import AlertsList from '../route/AlertsList';
    import TripList from '../route/TripList';
    import Sort from "../../assets/js/Sort";
    import Constant from '../route/Constant';

    let {
        DRIVER_VALUE, VEHICLE_VALUE, ALERT_VALUE, FLEET_VALUE,
        A_COLLISION_VALUE, A_PARKING_MODE_COLLISION_VALUE, A_DRIVER_ALERT_VALUE, A_UNPERMITTED_DRIVER_VALUE, A_ALL_VALUE
    } = Constant;

    export default {
        name: "RouteLeftBox",
        props: {
            loadingStatus: {type: Object, required: true},
            category: {type: String, required: true},
            alertCategory: {type: String, required: true},
            tripSummary: {type: Object, required: true},
            tripDriverList: {type: Array, required: true},
            tripFleetList: {type: Array, required: true},
            tripVehicleList: {type: Array, required: true},
            tripEventList: {type: Array, required: true},
            eventFromMap: {type: Object},
            apiVehicle: {type: Array, required: true},
            routeId: {type: [String, Number], required: true},
            startDate: {required: true}, // type: [String, null]
            endDate: {required: true}, // type: [String, null]
            isShowParkingAlert:{type:Boolean}
        },
        components: {
            ComLeft,
            AlertsList,
            TripList
        },
        mounted() {
            document.getElementById('contentBox').style.paddingBottom = '0px';
        },
        beforeDestroy() {
            if (document.getElementById('contentBox'))
                document.getElementById('contentBox').style.paddingBottom = '';
        },
        provide: function () {
            return {
                convertDateStrToLocal: this.convertDateStrToLocal
            }
        },
        data() {
            return {
                monthMap: new Map([
                    [1, 'January'], [2, 'February'], [3, 'March'], [4, 'April'], [5, 'May'], [6, 'June'],
                    [7, 'July'], [8, 'August'], [9, 'September'], [10, 'October'], [11, 'November'], [12, 'December'],
                ]),
                eventForAlerts: null, // 通过该变量修改Alert List的当前选项
                leftScrollTop: 0, // 左侧列表向上滚动的高度
            }
        },
        computed: {
            isShowTripDriver() {
                return this.category === DRIVER_VALUE;
            },
            isShowTripVehicle() {
                return this.category === VEHICLE_VALUE;
            },
            isShowTripFleet() {
                return this.category === FLEET_VALUE;
            },
            isShowCollision() {
                return this.category === ALERT_VALUE
                    && (
                        this.alertCategory === A_COLLISION_VALUE
                        || this.alertCategory === A_UNPERMITTED_DRIVER_VALUE
                        || this.alertCategory === A_PARKING_MODE_COLLISION_VALUE
                        || this.alertCategory === A_DRIVER_ALERT_VALUE
                        || this.alertCategory === A_ALL_VALUE
                    );
            },
            apiVehicleList() {
                let tmpVehicleList = Object.assign([], this.apiVehicle);
                tmpVehicleList.sort((vehicleA, vehicleB) => {
                    let idA = vehicleA.Id;
                    let idB = vehicleB.Id;
                    return Sort.NameSort(idA, idB);
                });
                return tmpVehicleList;
            },
            currentTripDriverListT() {
                this.tripDriverList.forEach(item => {
                    item.middleContent = item.VehicleId || this.$t('globalText.na');
                });
                return this.tripDriverList;
            },
            currentTripVehicleList() {
                this.tripVehicleList.forEach(item => {
                    if (item.type === 'trip')
                        item.middleContent = item.driverFullName || this.$t('globalText.unknown');
                });
                return this.tripVehicleList;
            },
            currentTripFleetList() {
                this.tripFleetList.forEach(item => {
                    if (item.type === 'trip')
                        item.middleContent = item.driverFullName || this.$t('globalText.unknown');
                });
                return this.tripFleetList;
            },
        },
        watch: {
            eventFromMap(event) {
                if (this.category === ALERT_VALUE)
                    this.eventForAlerts = event;
            },
        },
        methods: {
            handleFilterTripFlagRules(rules) {
                this.$emit('filter-driver-trip-flag-rules', rules);
            },
            handleTripDriverSelected(tripId, tripInfo) {
                this.$emit('trip-driver-selected', tripId, tripInfo);
            },
            handleTripVehicleSelected(tripId, tripInfo) {
                this.$emit('trip-vehicle-selected', tripId, tripInfo);
            },
            handleTripFleetSelected(tripId, tripInfo) {
                this.$emit('trip-fleet-selected', tripId, tripInfo);
            },
            handleTripCollisionChange(eventId, collisionInfo, redrawMap) {
                if (eventId) {
                    this.$emit('alert-collision-selected', {
                        redrawMap: !!redrawMap,
                        show: true,
                        data: collisionInfo,
                    });
                } else {
                    this.$emit('alert-collision-selected', {
                        redrawMap: true,
                        show: false,
                        data: {},
                    });
                }
            },
            handleTripCollisionChangeFromVehicleList(eventId, collisionInfo) {
                if (eventId) {
                    this.$emit('alert-collision-selected-from-vehicle-list', {
                        show: true,
                        data: collisionInfo,
                    });
                } else {
                    this.$emit('alert-collision-selected-from-vehicle-list', {
                        show: false,
                        data: {},
                    });
                }
            },
            handleScrollTop(height) {
                this.leftScrollTop = height;
            },
            convertDateStrToLocal(dateStr) {
                let [year, month, day] = dateStr.split('-');
                year = parseInt(year);
                month = parseInt(month);
                day = parseInt(day);
                if (this.$i18n.locale === this.$store.getters['i18n/Chinese_Simple']) {
                    return `${month}月${day}日`;
                } else if (this.$i18n.locale === this.$store.getters['i18n/Chinese_Taiwan']) {
                    return `${month}月${day}日`;
                } else {
                    let monthNamed = this.monthMap.get(month);
                    return monthNamed + ' ' + day;
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .rlb-no-data-box
        text-align center
        padding 41px
</style>
