<template>
    <div class="alert-trip-info common-scroll" ref="tripInfoBox">
        <top-driver :plate-number="tripInfo.VehicleId"
                    :fleet-id="fleetInfo.Id"
                    :fleet-name="fleetInfo.Name"
                    :fleet-color="fleetInfo.Color"
                    :driver-thumbnail="driverThumbnail"
                    :first-name="driverInfo.FirstName"
                    :last-name="driverInfo.LastName"
                    :show-avatar="false"
                    :driver-id="driverInfo.Id"
                    :vehicle-id="tripInfo.VehicleId"
                    :can-route-to-vehicle-info="!!vehicleInfo.Name"
                    :permitted="permitted"
        />
        <div class="ati-info-list">
            <div class="ati-row">
                <div class="ati-left ">{{$t('tripHistory.start')}}:</div>
                <div class="ati-right ati-addr-value" :title="startAddress">{{ startAddress }}</div>
            </div>
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.end')}}:</div>
                <div class="ati-right ati-addr-value" :title="endAddress">{{ endAddress }}</div>
            </div>
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.totalTime')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatTime(tripInfo.Duration) }}</div>
            </div>
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.idleTime')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatTime(tripInfo.IdleTime)}}</div>
            </div>
            <div class="ati-row" v-if="showDataAndUnit(convertDistanceNum)">
                <div class="ati-left">{{$t('tripHistory.distance')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatDataAndUnit(convertDistanceNum, unitKm) }}</div>
            </div>
            <div class="ati-row" v-if="showDataAndUnit(convertAvgSpeedNum)">
                <div class="ati-left">{{$t('tripHistory.avgSpeed')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatDataAndUnit(convertAvgSpeedNum, unitKmHr) }}</div>
            </div>
            <div class="ati-row" v-if="showDataAndUnit(convertMaxSpeedNum)">
                <div class="ati-left">{{$t('tripHistory.maxSpeed')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatDataAndUnit(convertMaxSpeedNum, unitKmHr) }}</div>
            </div>
            <div class="ati-row" v-if="showDataAndUnit(convertAvgFuelConsumption)">
                <div class="ati-left">{{$t('tripHistory.avgFuelConsumption')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatDataAndUnit(convertAvgFuelConsumption, unitKmL) }}</div>
            </div>
            <div class="ati-row" v-if="showDataAndUnit(convertIdleFuelConsumption)">
                <div class="ati-left">{{$t('tripHistory.idleFuelConsumption')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatDataAndUnit(convertIdleFuelConsumption, unitL) }}</div>
            </div>
            <div class="ati-row" v-if="showDataAndUnit(convertTotalFuelConsumption)">
                <div class="ati-left">{{$t('tripHistory.totalFuelConsumption')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatDataAndUnit(convertTotalFuelConsumption, unitL) }}</div>
            </div>
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.driverScore')}}:</div>
                <div class="ati-right td-text-hidden">{{ driverScore }}</div>
            </div>
        </div>
        <div class="ati-cate ati-collision">
            <div class="ati-row">
                <div class="ati-left">{{$tc('tripHistory.collisionAlerts', 1)}}<span class="ati-icon via-car-crash"
                                                                                     style="color: red;"/></div>
                <div class="ati-right td-text-hidden">
                    {{ tripInfo.CollisionCount || 0 }}
                    <span v-html="(tripInfo.CollisionCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
        </div>
        <div class="ati-cate ati-collision">
            <div class="ati-row">
                <div class="ati-left">{{$tc('tripHistory.driverAlerts', 1)}}<span class="ati-icon via-Driver-alert"
                                                                                  style="color: red;"/></div>
                <div class="ati-right td-text-hidden">
                    {{ tripInfo.DriverAlertCount || 0 }}
                    <span v-html="(tripInfo.DriverAlertCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
        </div>
        <div class="ati-cate ati-call" v-show="showTimesItemArea('call')">
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.way2Call')}}<span class="ati-icon via-microphone"
                                                                          style="color: #2eaf9e"/></div>
            </div>
        </div>
        <div class="ati-info-list ati-cat-box" v-show="showTimesItemArea('call')">
            <div class="ati-row" v-show="showTimesItem(tripInfo.DCallCount)">
                <div class="ati-left">{{$t('tripHistory.driver')}}:</div>
                <div class="ati-right td-text-hidden">
                    {{ tripInfo.DCallCount || 0 }}
                    <span v-html="(tripInfo.DCallCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.HCallCount)">
                <div class="ati-left">{{$t('tripHistory.headquarters')}}:</div>
                <div class="ati-right td-text-hidden">
                    {{ tripInfo.HCallCount || 0 }}
                    <span v-html="(tripInfo.HCallCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
        </div>
        <div class="ati-cate ati-g-sensor" v-show="showTimesItemArea('g-sensor')">
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.gSensorEvents')}}<span class="ati-icon via-flag"
                                                                               style="color: #00c6ff;"/></div>
            </div>
        </div>
        <div class="ati-info-list ati-cat-box" v-show="showTimesItemArea('g-sensor')">
            <div class="ati-row" v-show="showTimesItem(tripInfo.AccelerationCount)">
                <div class="ati-left">{{$t('tripHistory.hardRevving')}}:</div>
                <div class="ati-right td-text-hidden">
                    {{ tripInfo.AccelerationCount }}
                    <span v-html="(tripInfo.AccelerationCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.BrakingCount)">
                <div class="ati-left">{{$t('tripHistory.hardBraking')}}:</div>
                <div class="ati-right td-text-hidden">
                    {{ tripInfo.BrakingCount }}
                    <span v-html="(tripInfo.BrakingCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.CorneringCount)">
                <div class="ati-left">{{$t('tripHistory.hardCornering')}}:</div>
                <div class="ati-right td-text-hidden">{{ tripInfo.CorneringCount }}
                    <span v-html="(tripInfo.CorneringCount === 1) ? $tc('tripHistory.times', 1) : $tc('tripHistory.times', 2)"/>
                </div>
            </div>
        </div>
        <div class="ati-cate ati-adas" v-show="showTimesItemArea('adas')">
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.ADASEvents')}}<span class="ati-icon via-flag"
                                                                            style="color: #ff9c00;"/></div>
            </div>
        </div>
        <div class="ati-info-list ati-cat-box" v-show="showTimesItemArea('adas')">
            <div class="ati-row" v-show="showTimesItem(tripInfo.LDWCount)">
                <div class="ati-left">{{$t('tripHistory.LDW')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatCount(tripInfo.LDWCount) }}</div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.FCWCount)">
                <div class="ati-left">{{$t('tripHistory.FCW')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatCount(tripInfo.FCWCount) }}</div>
            </div>
        </div>
        <div class="ati-cate ati-dms" v-show="showTimesItemArea('dms')">
            <div class="ati-row">
                <div class="ati-left">{{$t('tripHistory.DMSEvents')}}<span class="ati-icon via-flag"
                                                                           style="color: #e354f0;"/></div>
            </div>
        </div>
        <div class="ati-info-list ati-cat-box" v-show="showTimesItemArea('dms')">
            <div class="ati-row" v-show="showTimesItem(tripInfo.FatigueCount)">
                <div class="ati-left">{{$t('tripHistory.driverFatigue')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatCount(tripInfo.FatigueCount) }}</div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.DistractedCount)">
                <div class="ati-left">{{$t('tripHistory.distractedDriving')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatCount(tripInfo.DistractedCount) }}</div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.SmokingCount)">
                <div class="ati-left">{{$t('tripHistory.smoking')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatCount(tripInfo.SmokingCount) }}</div>
            </div>
            <div class="ati-row" v-show="showTimesItem(tripInfo.PhoneCount)">
                <div class="ati-left">{{$t('tripHistory.phoneUsage')}}:</div>
                <div class="ati-right td-text-hidden">{{ formatCount(tripInfo.PhoneCount) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopDriver from "./TopDriver";
    import {numRound} from "../../../assets/js/Util";

    export default {
        name: "TripInformation",
        props: {
            tripInfo: {
                type: Object,
                required: true
            },
            driverInfo: {
                type: Object,
                required: true
            },
            fleetInfo: {
                type: Object,
                required: true
            },
            vehicleInfo: {
                type: Object,
                required: true
            },
            startAddress: {
                type: String,
                required: true
            },
            endAddress: {
                type: String,
                required: true
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.resizeHeight();
            });
        },
        components: {
            TopDriver
        },
        computed: {
            permitted() {
                return !(this.tripInfo.UnpermittedDriver && this.tripInfo.UnpermittedDriver === 1);
            },
            driverThumbnail() {
                // if (this.driverInfo && this.driverInfo.OtherPictures && this.driverInfo.OtherPictures[0]) {
                //     return this.$baseURL + this.driverInfo.OtherPictures[0];
                // }
                if (this.tripInfo.DriverPicturePath) {
                    return this.tripInfo.DriverPicturePath;
                }
                return false;
            },
            unitKm() {
                return this.$unit.getKmUnit(this);
            },
            unitKmHr() {
                return this.$unit.getKmHrUnit(this);
            },
            unitL() {
                return this.$unit.getLUnit(this);
            },
            unitKmL() {
                return this.$unit.getKmLUnit(this);
            },
            convertDistanceNum() {
                let value = this.$unit.convertKilometerUnit(this.tripInfo.Distance);
                return numRound(value, 1);
            },
            convertAvgSpeedNum() {
                let value = this.$unit.convertKilometerUnit(this.tripInfo.AverageSpeed);
                return numRound(value, 1);
            },
            convertMaxSpeedNum() {
                let value = this.$unit.convertKilometerUnit(this.tripInfo.MaxSpeed);
                return numRound(value, 1);
            },
            convertTotalFuelConsumption() {
                if (this.tripInfo.TotalFuelConsumed === false) return "N/A";
                // FuelConsumptionReal對應Total
                let value = this.$unit.convertLiterUnit(this.tripInfo.TotalFuelConsumed);
                return numRound(value, 1);
            },
            convertIdleFuelConsumption() {
                if (this.tripInfo.IdleTimeFuelConsumption === false) return "N/A";
                let value = this.$unit.convertLiterUnit(this.tripInfo.IdleTimeFuelConsumption);
                return numRound(value, 1);
            },
            convertAvgFuelConsumption() {
                if (this.tripInfo.FuelConsumptionRate === false) return "N/A";
                let value = this.$unit.convertKmLUnit(this.tripInfo.FuelConsumptionRate);
                return numRound(value, 1);
            },
            driverScore() {
                return numRound(this.tripInfo.DriverScore, 1);
            }
        },
        watch: {
            '$store.getters.screen'() {
                this.resizeHeight();
            }
        },
        methods: {
            resizeHeight() {
                let boxOffsetTop = this.$refs.tripInfoBox.offsetTop || 98,
                    parentBoxOffsetTop = this.$parent.$refs.ryDragBoxMsg.offsetTop,
                    historyBoxOffsetTop = document.getElementById('contentBox').offsetTop,
                    bodyHeight = document.documentElement.clientHeight,
                    totalOffset = boxOffsetTop + parentBoxOffsetTop + historyBoxOffsetTop;
                this.$refs.tripInfoBox.style.maxHeight = (bodyHeight - totalOffset - 10) + 'px';
            },
            formatCount(count) {
                if (isNaN(count) || typeof count !== 'number') return this.$t('globalText.na');
                if (count === 1) return count + ' ' + this.$tc('tripHistory.times', 1);
                return count + ' ' + this.$tc('tripHistory.times', 2);
            },
            showTimesItem(count) {
                return typeof count === "number" && !isNaN(count);
            },
            showTimesItemArea(area) {
                let dms = [
                    this.tripInfo.FatigueCount, this.tripInfo.DistractedCount,
                    this.tripInfo.SmokingCount, this.tripInfo.PhoneCount];
                let adas = [this.tripInfo.LDWCount, this.tripInfo.FCWCount];
                let call = [this.tripInfo.DCallCount, this.tripInfo.HCallCount];
                let gSensor = [this.tripInfo.AccelerationCount, this.tripInfo.BrakingCount, this.tripInfo.CorneringCount];
                let show = false, timesArr = [];
                switch (area) {
                    case 'call':
                        timesArr = call;
                        break;
                    case 'dms':
                        timesArr = dms;
                        break;
                    case 'adas':
                        timesArr = adas;
                        break;
                    case 'g-sensor':
                        timesArr = gSensor;
                        break;
                }
                timesArr.forEach(times => {
                    if (typeof times === "number" && times >= 0) show = true;
                });
                return show;
            },
            formatTime(time) {
                if (typeof time !== 'number' || time < 0 || time === 0) return '00:00:00';
                let second = time % 60;
                let minute = ((time - second) / 60) % 60;
                second = second < 10 ? '0' + second : second;
                let hours = (parseInt(time / 3600));
                minute = minute < 10 ? '0' + minute : minute;
                hours = hours < 10 ? '0' + hours : hours;
                return '' + hours + ':' + minute + ':' + second;
            },
            numRound(value, point) {
                if (isNaN(value)) return value;
                let numTmp = Math.pow(10, point);
                return Math.round(value * numTmp) / numTmp;
            },
            formatDataAndUnit(value, unit) {
                if (value === true) return this.$t('globalText.na');
                if ((value && value !== 'n/a' && value !== 'N/A') || value === 0) {
                    return value + ' ' + unit;
                }
                return this.$t('globalText.na');
            },
            showDataAndUnit(value) {
                if (value === true) return false;
                let newVar = (value && value !== 'n/a' && value !== 'N/A') || value === 0;
                return newVar;
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color = $color_black_first
    $second-blue = $color_blue_second
    $hover-bg-color = $color_blue_hover
    $layout-border-color = $color_gray_third
    $box-padding-left = 12px
    $box-padding-right = 12px
    .alert-trip-info
        color $font-color
        position relative
        background-color $color_white
        padding 0 0 10px 0
        cursor default
        min-height 0
        overflow-y auto

        .ati-top-box
            position relative
            height 74px

            .ati-left
                position absolute
                top 0
                left 0
                right 0
                padding-right 71px

                .ati-line
                    position relative
                    top 10px
                    margin-bottom 10px
                    color $font-color

                    .ati-number
                        color $font-color
                        white-space nowrap
                        display inline-block
                        overflow hidden
                        text-overflow ellipsis
                        position relative
                        top 3px

                    .ati-fleet-num
                        max-width 124px

                    .ati-plate-num
                        max-width 100px

                    .ati-vehicle-block
                        position relative
                        top 4px
                        font-size 22px
                        display inline-block
                        margin-right 2px
                        margin-left 1px

            .ati-right
                float right
                width 75px
                overflow hidden

                .ati-name
                    display inline-block
                    width 100%
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap
                    text-align center
                    color $font-color
                    cursor default

                .ati-avatar
                    width 54px
                    height 54px
                    border-radius 54px
                    overflow hidden
                    margin 0 auto
                    border 3px solid $layout-border-color

                    img
                        width 100%
                        height 100%

        .ati-info-list
            padding-left $box-padding-left
            padding-right $box-padding-right
            padding-top 15px
            border-bottom 1px solid $border-color
            border-top 1px solid $layout-border-color

            > .ati-row
                padding 5px 0 0
                position relative
                display flex
                justify-content space-between
                line-height 18px

                > .ati-left, > .ati-right
                    display inline-block

                > .ati-left
                    max-width 195px
                    white-space nowrap

                > .ati-right
                    /*width 140px*/
                    text-align right

                    > .ati-icon
                        display inline-block !important
                        width 18px !important


                > .no-flag
                    padding-right 14px

                /*width 122px*/

                > .ati-addr-name
                    /*width 45px*/
                    position absolute

                > .ati-addr-value
                    text-align left
                    overflow hidden
                    text-overflow ellipsis
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    max-width calc(100% - 50px)
                    word-wrap break-word

        .ati-cate
            padding-left 12px
            padding-right 12px
            margin-top 10px

            .ati-icon
                padding-left 8px

            > .ati-row
                padding 3px 0
                position relative
                display flex
                justify-content space-between
                line-height 18px

                > .ati-left, > .ati-right
                    display inline-block

                > .ati-left
                    max-width 195px

                > .ati-right
                    text-align right

        .ati-collision
            background-color #FFE8E8

        .ati-call
            background-color #e1ffef

        .ati-g-sensor
            background-color #E1EFFF

        .ati-adas
            background-color #FFFBE2
            margin-top 5px

        .ati-dms
            background-color #FFEFFC
            margin-top 5px

        .ati-cat-box
            padding-top 0
            border-top 0

    .td-text-hidden
        overflow hidden
        text-overflow ellipsis
        white-space nowrap

    .common-scroll::-webkit-scrollbar
        width: 10px;

    .common-scroll::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;

    .common-scroll::-webkit-scrollbar-thumb
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
</style>
