<template>
    <div class="collision-info-alert-box" ref="collisionInfoAlertBox">
        <com-top class="top-blue-border"
                 v-drag="{that: this}" :title="vehicleInfo.Name || $t('globalText.unknown')"
                 @close="handleClose"/>
        <top-driver class="ciab-top-driver grey-border-left grey-border-right"
                    :permitted="permitted"
                    :driver-id="collisionInfo.DriverId"
                    :vehicle-id="collisionInfo.VehicleId"
                    :first-name="driverInfo.FirstName"
                    :last-name="driverInfo.LastName"
                    :driver-thumbnail="currentDriverThumbnail"
                    :fleet-id="fleetInfo.Id"
                    :fleet-color="fleetInfo.Color"
                    :fleet-name="fleetInfo.Name"
                    :can-route-to-vehicle-info="!!vehicleInfo.Name"
                    :plate-number="vehicleInfo.Id"/>
        <div class="ciab-alert-type-box grey-border-left grey-border-right" v-if="collisionInfo.Event === 'Collision'">
            <i class="ciab-icon via-car-crash"/>{{$tc('tripHistory.collisionAlerts', 1)}}
        </div>
        <div class="ciab-alert-type-box grey-border-left grey-border-right" v-if="collisionInfo.Event === 'DriverAlert'">
            <i class="ciab-icon via-Driver-alert"/>{{$tc('tripHistory.driverAlerts', 1)}}
        </div>
        <div class="ciab-alert-type-box grey-border-left grey-border-right" v-if="collisionInfo.Event === 'ParkingCollision'">
            <i class="ciab-icon via-Parking-alert"/>{{$tc('tripHistory.parkingAlerts', 1)}}
        </div>
        <div class="ciab-alert-type-box grey-border-left grey-border-right" v-if="collisionInfo.Event === 'Unpermitted'">
            <i class="ciab-icon via-Unpermitted"/>{{$tc('tripHistory.unpermittedDrivers', 1)}}
        </div>
        <div class="ciab-collision-info grey-border-left grey-border-right">
            <div v-if="collisionInfo.Event === 'DriverAlert'
                        || collisionInfo.Event === 'ParkingCollision'
                        || collisionInfo.Event === 'Collision'"
                 class="ciab-row">
                <div class="ciab-left msg-word-one-line"
                     :title="this.$dateFormat.dateTime(collisionInfo.Timestamp, mixin_dateTimeFormat, mixin_timezone)">
                    <span class="ciab-icon via-hours"/>{{this.$dateFormat.dateTime(collisionInfo.Timestamp, mixin_dateTimeFormat, mixin_timezone)}}
                </div>
                <div class="ciab-right msg-word-one-line"
                     v-if="collisionInfo.Event === 'DriverAlert' || collisionInfo.Event === 'Collision'"
                     :title="formatDataAndUnit(speed, unitKmHr)">
                    <span class="ciab-icon via-speedometer"/>{{ formatDataAndUnit(speed, unitKmHr) }}
                </div>
            </div>
            <div v-if="collisionInfo.Event === 'DriverAlert'
                        || collisionInfo.Event === 'ParkingCollision'
                        || collisionInfo.Event === 'Collision'"
                 class="ciab-row ciab-location ciab-word-show">
                <span class="ciab-icon via-map-marker"/>
                <span :title="address">{{ address }}</span>
            </div>
            <div v-if="collisionInfo.Event === 'Unpermitted'" class="ciab-row">
                <span class="ciab-icon via-hours"/>{{this.$dateFormat.time(collisionInfo.Timestamp, mixin_timeFormat, mixin_timezone)}} -
                {{this.$dateFormat.time(endTimestamp, mixin_timeFormat, mixin_timezone)}}
            </div>
            <div v-if="collisionInfo.Event === 'Unpermitted'" class="ciab-row ciab-location-box ciab-word-show">
                <div class="ciab-tips">{{$t('tripHistory.start')}}:</div>
                <div class="ciab-location ciab-content" :title="address">{{ address }}</div>
            </div>
            <div v-if="collisionInfo.Event === 'Unpermitted'" class="ciab-row ciab-location-box ciab-word-show">
                <div class="ciab-tips">{{$t('tripHistory.end')}}:</div>
                <div class="ciab-location ciab-content" :title="endAddress">{{ endAddress }}</div>
            </div>
        </div>
        <div class="ciab-media grey-border-left grey-border-right grey-border-bottom"
             v-if="collisionInfo.Event === 'DriverAlert'
                        || collisionInfo.Event === 'ParkingCollision'
                        || collisionInfo.Event === 'Collision'">
            <div class="ciab-row">
                <video v-if="collisionInfo.VideoFPath" ref="bottomCamera"
                       id="_alert_video_f_path"
                       :src="collisionInfo.VideoFPath" width="100%" controls
                       @play="onPlay('_alert_video_f_path')"
                       @error="onVideoLoadError('f')"
                       disablePictureInPicture/>
                <img v-else :src="collisionInfo.ImageFPath || '../../assets/image/video_error_holder.png'"
                     :onerror='defaultImage' width="100%"/>
            </div>
            <div class="ciab-row">
                <video v-if="collisionInfo.VideoRPath" ref="topCamera"
                       id="_alert_video_r_path"
                       :src="collisionInfo.VideoRPath" width="100%" controls
                       @play="onPlay('_alert_video_r_path')"
                       @error="onVideoLoadError('r')"
                       disablePictureInPicture/>
                <img v-else :src="collisionInfo.ImageRPath || '../../assets/image/video_error_holder.png'"
                     :onerror='defaultImage' width="100%"/>
            </div>
            <div v-if="collisionInfo.VideoCPath">
                <div class="ciab-row">
                    <video v-if="collisionInfo.VideoCPath" ref="sideCamera"
                           id="_alert_video_c_path"
                           :src="collisionInfo.VideoCPath" width="100%" controls
                           @play="onPlay('_alert_video_c_path')"
                           @error="onVideoLoadError('c')"
                           disablePictureInPicture/>
                    <img v-else :src="collisionInfo.ImageCPath || '../../assets/image/video_error_holder.png'"
                         :onerror='defaultImage' width="100%"/>
                </div>
            </div>

        </div>
        <div class="ciab-media  grey-border-left grey-border-right grey-border-bottom" v-if="collisionInfo.Event === 'Unpermitted'">
            <div class="ciab-row">
                <img :src="collisionInfo.DriverPicturePath || '../../assets/image/video_error_holder.png'"
                     :onerror='defaultImage' width="100%"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ComTop from './alertMessage/ComTop';
    import TopDriver from "./alertMessage/TopDriver";
    import bus from "../../utils/bus";
    import {numRound} from "../../assets/js/Util";

    export default {
        name: "CollisionInfoAlertBox",
        props: {
            collisionInfo: {
                type: Object,
                required: true
            },
            driverInfo: {
                type: Object,
                required: true
            },
            fleetInfo: {
                type: Object,
                required: true
            },
            vehicleInfo: {
                type: Object,
                required: true
            },
            addressGps: {
                type: Object,
                required: true
            },
            endAddressGps: {
                type: Object,
                required: true
            },
            endTimestamp: {
                required: true
            },
            driverPicturePath: {
                type: String,
                required: true
            }
        },
        components: {
            ComTop,
            TopDriver
        },
        mounted() {
            bus.$on('pauseVideo', this.stopPlay);
            this.getGpsAddress(this.addressGps.latitude, this.addressGps.longitude);
            this.getEndGpsAddress(this.endAddressGps.latitude, this.endAddressGps.longitude);
        },
        beforeDestroy() {
            this.isDestroying = true;
            let topCamera = this.$refs.topCamera,
                bottomCamera = this.$refs.bottomCamera;
            if (topCamera && topCamera.src) {
                topCamera.src = '';
                topCamera.load();
            }
            if (bottomCamera && bottomCamera.src) {
                bottomCamera.src = '';
                bottomCamera.load();
            }
        },
        data() {
            return {
                isDestroying: false,
                lastVideo: null,
                lastVideoId: null,
                address: '',
                endAddress: '',
                defaultImage: 'this.src="' + require('../../assets/image/video_error_holder.png') + '"'
            }
        },
        computed: {
            permitted() {
                return !(this.collisionInfo.UnpermittedDriver && this.collisionInfo.UnpermittedDriver === 1);
            },
            speed() {
                let speed = this.$unit.convertKilometerUnit(this.collisionInfo.Speed);
                return numRound(speed, 1);
            },
            unitKmHr() {
                return this.$unit.getKmHrUnit(this);
            },
            currentDriverThumbnail() {
                if (this.driverInfo.OtherPictures && this.driverInfo.OtherPictures[0])
                    return this.$baseURL + this.driverInfo.OtherPictures[0];
                return '';
            }
        },
        watch: {
            addressGps(gps) {
                this.getGpsAddress(gps.latitude, gps.longitude);
            },
            endAddressGps(gps) {
                this.getEndGpsAddress(gps.latitude, gps.longitude);
            }
        },
        methods: {
            onPlay(id) {
                if (this.lastVideo && this.lastVideoId !== id)
                    this.lastVideo.pause();
                this.lastVideo = document.getElementById(id);
                this.lastVideoId = id;
            },
            onVideoLoadError(flag) {
                if (flag === 'f' && this.isDestroying === false)
                    this.collisionInfo.VideoFPath = '';
                if (flag === 'r' && this.isDestroying === false)
                    this.collisionInfo.VideoRPath = '';
                if (flag === 'c' && this.isDestroying === false)
                    this.collisionInfo.VideoCPath = '';
            },
            getGpsAddress(latitude, longitude) {
                this.$Gps.getLocation(latitude, longitude)
                    .then(location => this.address = location)
                    .catch(location => this.address = location);
            },
            getEndGpsAddress(latitude, longitude) {
                this.$Gps.getLocation(latitude, longitude)
                    .then(location => this.endAddress = location)
                    .catch(location => this.endAddress = location);
            },
            handleClose() {
                this.$emit('close');
                this.stopPlay();
            },
            stopPlay() {
                if (this.$refs.topCamera) {
                    this.$refs.topCamera.pause();
                }
                if (this.$refs.bottomCamera) {
                    this.$refs.bottomCamera.pause();
                }
            },
            getTimeStr(timestamp) {
                if (timestamp === 0 || timestamp * 1000 === 0 || isNaN(timestamp)) return this.$t('globalText.na');
                let date = new Date(timestamp * 1000);
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let seconds = date.getSeconds();
                hours = hours < 10 ? '0' + hours : '' + hours;
                minutes = minutes < 10 ? '0' + minutes : '' + minutes;
                seconds = seconds < 10 ? '0' + seconds : '' + seconds;
                return hours + ':' + minutes + ':' + seconds;
            },
            getSeed(speed) {
                return this.$unit.convertKilometerUnit(speed);
            },
            formatDataAndUnit(value, unit) {
                if (value === true) return this.$t('globalText.na');
                if ((value && value !== 'n/a' && value !== 'N/A') || value === 0) {
                    return value + ' ' + unit;
                }
                return this.$t('globalText.na');
            }
        },
        directives: {
            drag(el, binding) {
                el.onmousedown = (e) => {
                    let elBox = binding.value.that.$refs.collisionInfoAlertBox;
                    let oriX = e.pageX;
                    let oriY = e.pageY;
                    let documentWidth = document.getElementById('ryFullScreen').clientWidth;
                    let documentHeight = document.getElementById('ryFullScreen').clientHeight;
                    let leftWidth = document.getElementById('ryLeft').clientWidth;
                    let btnWidth = document.getElementById('ryFleetShowBtn').clientWidth;
                    btnWidth = 0;
                    let bottomHeight = document.getElementById('ryBottom').clientHeight;
                    let dragBoxWidth = documentWidth - leftWidth - btnWidth;
                    let dragBoxHeight = documentHeight - bottomHeight;
                    let selfWidth = el.clientWidth;
                    let selfHeight = el.clientHeight;
                    let curX = elBox.style.right.replace('px', '');
                    let curY = elBox.style.top.replace('px', '');
                    curX = curX === '' ? 60 : parseInt(curX);
                    curY = curY === '' ? 0 : parseInt(curY);
                    document.onmousemove = function (e) {
                        let xOffset = oriX - e.pageX;
                        let yOffset = e.pageY - oriY;
                        let newX = curX + xOffset;
                        let newY = curY + yOffset;
                        newX = newX < 0 ? 0 : newX;
                        newY = newY < 0 ? 0 : newY;
                        newX = newX < (dragBoxWidth - selfWidth) ? newX : (dragBoxWidth - selfWidth);
                        newY = newY < (dragBoxHeight - selfHeight) ? newY : (dragBoxHeight - selfHeight);
                        elBox.style.right = newX + 'px';
                        elBox.style.top = newY + 'px';
                    };
                    document.onmouseup = function () {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $box-padding-left = 12px
    $box-padding-right = 12px
    .collision-info-alert-box
        position absolute
        right 60px
        top 15px
        width 320px

        .top-blue-border
            border-left 1px solid $color_blue_first
            border-right 1px solid $color_blue_first

        .grey-border-left
            border-left 1px solid $color_gray_third

        .grey-border-right
            border-right 1px solid $color_gray_third

        .grey-border-bottom
            border-bottom 1px solid $color_gray_third

        .ciab-alert-type-box
            line-height 14px
            font-weight 600
            padding 10px 12px
            background-color #ffe8e8

            .ciab-icon
                width 22px
                display inline-block

        .msg-word-one-line
            white-space nowrap
            text-overflow ellipsis
            overflow hidden

        > .ciab-top-driver
            border-bottom 1px solid $color_gray_third

        > .ciab-collision-info
            background-color #ffffff
            padding-left $box-padding-left
            padding-right $box-padding-right
            padding-top 15px
            padding-bottom 1px
            cursor default
            border-bottom 1px solid $color_gray_third

            > .ciab-location-box
                display flex
                justify-content space-between

                .ciab-tips
                    width 50px
                    line-height 16px

                .ciab-content
                    max-width calc(100% - 50px)
                    text-align left

            > .ciab-row
                margin-bottom 15px

                .ciab-icon
                    padding-right 8px

                > .ciab-left, > .ciab-right
                    display inline-block

                > .ciab-left
                    width calc(100% - 130px)

                > .ciab-right
                    text-align right
                    width 130px

            .ciab-location
                overflow hidden
                text-overflow ellipsis
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 2
                word-break all

            .ciab-word-show
                word-break keep-all
                word-wrap break-word

        > .ciab-media
            background-color #ffffff
            padding-left $box-padding-left
            padding-right $box-padding-right
            padding-top 8px
            padding-bottom 0

            > .ciab-row
                padding-bottom 8px

                > .ciab-img
                    width 100%
</style>
