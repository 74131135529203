export default {
    // category 相关
    DRIVER_LABEL: 'Driver', VEHICLE_LABEL: 'Vehicle', ALERT_LABEL: 'Alert', FLEET_LABLE: 'Fleet',
    DRIVER_VALUE: 'Driver', VEHICLE_VALUE: 'Vehicle', ALERT_VALUE: 'Alert', FLEET_VALUE: 'Fleet',
    // alert event 相关
    A_COLLISION_LABEL: 'Collision Alert', A_PARKING_MODE_COLLISION_LABLE: 'Parking Alert',
    A_COLLISION_VALUE: 'Collision', A_PARKING_MODE_COLLISION_VALUE: 'ParkingCollision',
    A_DRIVER_ALERT_LABEL: 'Driver Alert', A_UNPERMITTED_DRIVER_LABLE: 'Unpermitted Driver',
    A_DRIVER_ALERT_VALUE: 'DriverAlert', A_UNPERMITTED_DRIVER_VALUE: 'Unpermitted',
    A_ALL_LABEL: 'All',
    A_ALL_VALUE: 'All',
    // 获取api相关的数据使用
    API_VEHICLE_DATA: 'apiVehicleData', API_DRIVER_DATA: 'apiDriverData', API_FLEET_DATA: 'apiFleetData',
    // 日期选择框控制的显示使用
    DATE_DAYS_LIST: 'date-days-list',
    // 改变所有的值
    CHANGE_ALL_DATA: 'change-category-cateValue-date',
    // 清空历史的选项记录
    CLEAR_ALL_HISTORY_DATA: 'clear-all-history-data'
}