<template>
    <div class="alert-list-box">
        <div class="alb-group-box" v-show="!tips.show" v-for="(groupInfo, groupIndex) in currentCollisionList"
             :key="groupIndex">
            <div class="alb-row alb-title" v-if="startDate !== endDate">
                {{ convertDateStrToLocal(groupInfo.date) }} ({{ groupInfo.total }})
            </div>
            <div class="alb-row"
                 :class="{'alb-active': (currentGroupIndex === groupIndex && currentListIndex === listIndex), 'alb-row-icon': showAlertIcon}"
                 @click="handleAlertClick(groupIndex, listIndex)"
                 v-for="(item, listIndex) in groupInfo.list" :key="listIndex + '-' + groupIndex">
                <span class="alb-left" :class="['alb-left-' + indexLength]"
                      :title="$t('tripHistory.alert') + ' - ' + item.num">
                    <span class="alb-icon-box" v-if="showAlertIcon">
                        <i v-if="item.Event === 'Collision'" class="via-car-crash"/>
                        <i v-if="item.Event === 'DriverAlert'" class="via-Driver-alert"/>
                        <i v-if="item.Event === 'ParkingCollision'" class="via-Parking-alert"/>
                        <i v-if="item.Event === 'Unpermitted'" class="via-Unpermitted"/>
                    </span>{{$t('tripHistory.alert')}} - {{ item.num }}</span>
                <span class="alb-name" :class="['alb-name-' + indexLength]" :title="item.VehicleId || $t('globalText.unknown')">{{ item.VehicleId || $t('globalText.unknown') }}</span>
                <span class="alb-right" v-if="item.Timestamp === 0" title="N/A">{{$t('globalText.na')}}</span>
                <span class="alb-right" v-else
                      :title="$dateFormat.time(item.Timestamp, mixin_timeFormat, mixin_timezone)">{{ $dateFormat.time(item.Timestamp, mixin_timeFormat, mixin_timezone) }}</span>
            </div>
        </div>
        <div class="alb-no-data-box" v-show="tips.show">{{ i18nTipsConvert(tips.content) }}</div>
    </div>
</template>

<script>
    const LOADING = 'Loading...';
    const NO_DATA = 'No Data.';

    export default {
        name: "AlertsList",
        props: {
            showAlertIcon: {
                type: Boolean,
                default: false
            },
            collisionList: {
                type: Array,
                required: true
            },
            vehicleList: {
                type: Array,
                required: true
            },
            newAlert: {
                required: true
            },
            routeEventId: { // 通过该变量修改路由跳转的逻辑
                type: String
            },
            loadingStatus: {
                type: Object,
                required: true
            },
            startDate: { // type: [String, null]
                required: true
            },
            endDate: { // type: [String, null]
                required: true
            },
        },
        inject: ['leftContentScrollBox', 'convertDateStrToLocal'],
        mounted() {
            this.$nextTick(() => {
                if (this.collisionList.length) {
                    this.handleAlertClick(0, 0);
                }
            });
        },
        data() {
            return {
                currentGroupIndex: -1,
                currentListIndex: -1,
                currentEventId: null,
                lastRouteEventId: null,
                hasHandleRouteEventId: false,
                tips: {
                    show: true,
                    content: LOADING
                }
            };
        },
        computed: {
            currentCollisionList() {
                let list = [];
                this.collisionList.forEach(item => {
                    list.push(item);
                });
                let length = list.length;
                if (length < 1) return [];
                list.sort((itemA, itemB) => {
                    return itemA.Timestamp - itemB.Timestamp;
                });
                let alertGroupList = [],
                    alertItem = null,
                    alertNum = 0,
                    globalIndex = 0,
                    groupIndex = 0;
                list.forEach((alertInfo) => {
                    alertInfo.index = globalIndex;
                    globalIndex++;
                    if (alertItem && alertItem.date === alertInfo.Date) {
                        alertNum++;
                        alertInfo.num = alertNum;
                        alertItem.list.push(alertInfo);
                        alertItem.total = alertNum;
                    } else {
                        if (alertItem) alertGroupList.push(alertItem);
                        alertInfo.num = 1;
                        alertItem = {
                            date: alertInfo.Date,
                            total: 1,
                            groupIndex: groupIndex,
                            list: [alertInfo]
                        };
                        alertNum = 1;
                        groupIndex++;
                    }
                });
                if (alertItem && alertGroupList.length > 0)
                    alertGroupList.push(alertItem);
                if (alertItem && alertGroupList.length === 0)
                    alertGroupList.push(alertItem);
                return alertGroupList;
            },
            indexLength() {
                let maxLength = 1;
                this.currentCollisionList.forEach(item => {
                    if (item.total > maxLength)
                        maxLength = item.total;
                });
                return (maxLength + '').length;
            }
        },
        watch: {
            loadingStatus(loading) {
                if (loading.startLoad) {
                    this.tips.show = true;
                    this.tips.content = LOADING;
                }
            },
            currentCollisionList() {
                if (this.currentCollisionList.length === 0) {
                    this.tips.show = true;
                    this.tips.content = NO_DATA;
                } else {
                    this.tips.show = false;
                    this.tips.content = LOADING;
                }
                if (this.currentCollisionList.length) {
                    if (
                        (this.routeEventId && this.hasHandleRouteEventId === false)
                        || (this.routeEventId && this.routeEventId !== this.lastRouteEventId)
                    ) {
                        this.hasHandleRouteEventId = true;
                        this.lastRouteEventId = this.routeEventId;
                        let groupIndex = -1, listIndex = -1;
                        this.currentCollisionList.forEach((groupInfor, gIndex) => {
                            groupInfor.list.forEach((item, lIndex) => {
                                if (item.EventId === this.routeEventId) {
                                    groupIndex = gIndex;
                                    listIndex = lIndex;
                                }
                            });
                        });
                        if (groupIndex !== -1 && listIndex !== -1) {
                            this.handleAlertClick(groupIndex, listIndex);
                        } else {
                            this.currentGroupIndex = -1;
                            this.currentListIndex = -1;
                            this.$emit('changeCollision', null, {});
                        }
                        let scrollHeight = this.getScrollTopHeight(groupIndex, listIndex);
                        this.$emit('scrollTop', scrollHeight);
                    } else {
                        this.handleAlertClick(0, 0);
                    }
                }
            },
            newAlert(collision) {
                let groupIndex = -1, listIndex = -1;
                this.currentCollisionList.forEach((groupInfor, gIndex) => {
                    groupInfor.list.forEach((item, lIndex) => {
                        if (item.EventId === collision.eventId) {
                            groupIndex = gIndex;
                            listIndex = lIndex;
                        }
                    });
                });
                if (groupIndex !== -1 && listIndex !== -1) {
                    this.currentEventId = collision.eventId;
                    let alertInfo = (this.currentCollisionList[groupIndex].list)[listIndex];
                    this.$emit('changeCollision', collision.eventId, alertInfo, false);
                    let scrollHeight = this.getScrollTopHeight(groupIndex, listIndex);
                    this.$emit('scrollTop', scrollHeight);
                    this.currentGroupIndex = groupIndex;
                    this.currentListIndex = listIndex;
                } else {
                    this.currentGroupIndex = -1;
                    this.currentListIndex = -1;
                    this.$emit('changeCollision', null, {});
                }
            },
        },
        methods: {
            i18nTipsConvert(content) {
                let newContent = '';
                switch (content) {
                    case LOADING:
                        newContent = this.$t('globalText.loading');
                        break;
                    case NO_DATA:
                        newContent = this.$t('globalText.noData');
                        break;
                    default:
                        newContent = this.$t('globalText.loading');
                        break;
                }
                return newContent;
            },
            getTimeStr(timestamp) {
                if (isNaN(timestamp) || timestamp === 0) return this.$t('globalText.na');
                let date = new Date(timestamp * 1000);
                let hour = date.getHours();
                let minute = date.getMinutes();
                let seconds = date.getSeconds();
                hour = hour < 10 ? '0' + hour : hour + '';
                minute = minute < 10 ? '0' + minute : minute + '';
                seconds = seconds < 10 ? '0' + seconds : seconds + '';
                return hour + ':' + minute + ':' + seconds;
            },
            handleAlertClick(groupIndex, listIndex) {
                this.currentGroupIndex = groupIndex;
                this.currentListIndex = listIndex;
                let collisionInfo = (this.currentCollisionList[groupIndex].list)[listIndex];
                this.currentEventId = collisionInfo.EventId;
                let redrawMap = true;
                if (collisionInfo) {
                    this.$emit('changeCollision', collisionInfo.EventId, collisionInfo, redrawMap);
                    let scrollHeight = this.getScrollTopHeight(groupIndex, listIndex);
                    this.$emit('scrollTop', scrollHeight);
                } else {
                    this.$emit('changeCollision', null, {});
                }
            },
            getScrollTopHeight(groupIndex, listIndex) {
                if (groupIndex < 0 || listIndex < 0) return 0;
                let itemCount = 0;
                this.currentCollisionList.forEach((groupInfo, gIndex) => {
                    if (gIndex < groupIndex) itemCount += groupInfo.list.length;
                    if (gIndex === groupIndex) itemCount += (listIndex + 1);
                });
                let itemHeight = 44,
                    titleHeight = 44,
                    scrollBox = this.leftContentScrollBox(),
                    minViewHeight = scrollBox.scrollTop,
                    maxViewHeight = minViewHeight + scrollBox.clientHeight,
                    minIndexHeight = (itemCount - 1) * itemHeight + (groupIndex + 1) * titleHeight + groupIndex,
                    maxIndexHeight = itemCount * itemHeight + (groupIndex + 1) * titleHeight + groupIndex;
                if (minIndexHeight < minViewHeight) return minIndexHeight;
                if (maxIndexHeight > maxViewHeight) return maxIndexHeight - scrollBox.clientHeight;
                return minViewHeight;
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .alert-list-box
        cursor pointer
        border-top 1px solid $color_gray_third

        .alb-group-box
            border-bottom 1px solid $color_gray_third

        .alb-title
            cursor default !important
            border-bottom 1px solid $color_gray_third !important

        .alb-title:hover
            background-color rgba(0, 0, 0, 0) !important

        .alb-no-data-box
            text-align center
            padding-top 40px
            cursor default

        .alb-row
            height 43px
            line-height 43px
            padding-left 10px
            padding-right 10px
            border-bottom 1px solid rgba(0, 0, 0, 0)

            .alb-left, .alb-right, .alb-name
                display inline-block
                overflow hidden
                text-overflow ellipsis
                white-space nowrap

            .alb-left
                text-align left
                width 67px

                .alb-icon-box
                    display inline-block
                    width 25px

            .alb-left-2
                width 76px

            .alb-left-3
                width 85px

            .alb-left-4
                width 95px

            .alb-left-5, .alb-left-6, .alb-left-7
                width 105px

            .alb-right
                text-align right
                width 60px

            .alb-name
                width calc(100% - 127px)

            .alb-name-2
                width calc(100% - 136px)

            .alb-name-3
                width calc(100% - 145px)

            .alb-name-4
                width calc(100% - 155px)

            .alb-name-5, .alb-name-6, .alb-name-7
                width calc(100% - 165px)

        .alb-row-icon
            .alb-left
                width 93px

            .alb-left-2
                width 100px

            .alb-left-3
                width 110px

            .alb-left-4
                width 120px

            .alb-left-5, .alb-left-6, .alb-left-7
                width 130px

            .alb-right
                width 60px

            .alb-name
                width calc(100% - 153px)

            .alb-name-2
                width calc(100% - 160px)

            .alb-name-3
                width calc(100% - 170px)

            .alb-name-4
                width calc(100% - 180px)

            .alb-name-5, .alb-name-6, .alb-name-7
                width calc(100% - 190px)

        .alb-active
            background-color $color_blue_second !important
            color $color_white

        .alb-row:hover
            background-color $color_blue_hover
</style>
