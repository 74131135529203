<template>
    <div class="ry-main">
        <div class="ry-top">
            <slot name="top"></slot>
        </div>
        <div class="ry-middle ry-scroll" ref="leftMiddleContentBox">
            <slot name="default">
            </slot>
        </div>
        <div class="ry-bottom" ref="leftBottomContentBox">
            <slot name="bottom"></slot>
        </div>
    </div>
</template>

<script>
    import bus from '../../utils/bus';


    export default {
        name: "ComLeft",
        components: {},
        mounted() {
            if (this.topHeight) {
                this.$refs.leftMiddleContentBox.style.top = this.topHeight + 'px';
            }
            if (this.bottomHeight) {
                this.$refs.leftMiddleContentBox.style.bottom = this.bottomHeight + 'px';
                this.$refs.leftBottomContentBox.style.height = this.bottomHeight + 'px';
            }
            bus.$on('handleShowBottom', this.handleShowBottom);
        },
        props: {
            topHeight: {
                type: Number
            },
            bottomHeight: {
                type: Number
            },
            leftScrollTop: {
                type: Number
            }
        },
        provide: function() {
            return {
                leftContentScrollBox: this.contentScrollBox
            };
        },
        watch: {
            topHeight() {
                this.$refs.leftMiddleContentBox.style.top = this.topHeight + 'px';
            },
            bottomHeight() {
                this.$refs.leftMiddleContentBox.style.bottom = this.bottomHeight + 'px';
                this.$refs.leftBottomContentBox.style.height = this.bottomHeight + 'px';
            },
            leftScrollTop(height) {
                this.$nextTick(() => {
                    this.$refs.leftMiddleContentBox.scrollTop = height;
                });
            }
        },
        data() {
            return {
                showBottom: true
            }
        },
        methods: {
            handleShowBottom(miniBottomHeight) {
                this.showBottom = !this.showBottom;
                let boxHeight = 0;
                if (miniBottomHeight) {
                    boxHeight = miniBottomHeight;
                }
                if (this.showBottom) {
                    boxHeight = this.bottomHeight;
                }
                this.$refs.leftBottomContentBox.style.height = boxHeight + 'px';
                this.$refs.leftMiddleContentBox.style.bottom = boxHeight + 'px';
            },
            contentScrollBox() {
                return this.$refs.leftMiddleContentBox;
            }
        },

    }
</script>

<style lang="stylus" scoped>
    $transition-time = .5s
    .ry-main
        width 100%
        position relative

        .ry-top, .ry-bottom, .ry-middle
            position absolute
            left 0
            right 0
            transition-duration $transition-time
            -moz-transition-duration $transition-time /* Firefox 4 */
            -webkit-transition-duration $transition-time /* Safari 和 Chrome */
            -o-transition-duration $transition-time

        /* Opera */

        .ry-top
            z-index 3
            top 0
            overflow hidden

        .ry-middle
            z-index 1
            top -1px
            bottom -1px
            overflow-x hidden
            -ms-overflow-x hidden
            border-top 1px solid $border-color
            border-bottom 1px solid $border-color

        .ry-bottom
            z-index 2
            bottom 0
            overflow hidden

        .ry-scroll::-webkit-scrollbar
            width: 10px;

        .ry-scroll::-webkit-scrollbar-track
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;

        .ry-scroll::-webkit-scrollbar-thumb
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

</style>
