<template>
    <div class="msg-drag-box-v2" ref="ryDragBoxMsg">
        <div class="msg-com-alert-msg-history">
            <com-top class="top-blue-border"
                     v-drag="{that: this}"
                     :title="vehicleInfo.Name || $t('globalText.unknown') || 'Trip ' + (tripInfo.index + 1)"
                     @close="handleClose"/>
            <com-buttons class="grey-border-left grey-border-right"
                         :name="activeBtn"
                         :show-voice="showCallBtn"
                         :show-collision="showCollisionBtn"
                         @activeType="handleActiveButtonTypeChange"/>
            <call-list ref="callList"
                       v-show="activeBtn === 'call'"
                       :h-call-list="hCallList"
                       :auto-resize="true"/>
            <trip-information class="grey-border-left grey-border-right grey-border-bottom"
                              ref="tripInformation"
                              v-show="activeBtn === 'information'"
                              :start-address="startAddress"
                              :end-address="endAddress"
                              :trip-info="tripInfo"
                              :driver-info="driverInfo"
                              :vehicle-info="vehicleInfo"
                              :fleet-info="fleetInfo"/>
            <trip-collision class="grey-border-left grey-border-right grey-border-bottom"
                            v-show="activeBtn === 'collision'"
                            ref="tripCollision"
                            :auto-resize="true"
                            :event-list="eventList"/>
        </div>
    </div>
</template>

<script>
import ComTop from './alertMessage/ComTop';
import TripCollision from "./alertMessage/TripCollision";
import TripInformation from './alertMessage/TripInformation';
import ComButtons from "./alertMessage/ComButtons";
import CallList from "../route/CallList";
import {parseVehicleModel} from "../../assets/js/Util";

export default {
        name: "ComAlertMessageHistoryV2",
        props: {
            tripInfo: {type: Object, required: true},
            driverInfo: {type: Object, required: true},
            fleetInfo: {type: Object,},
            vehicleInfo: {type: Object},
            endAddressGps: {type: Object, required: true},
            startAddressGps: {type: Object, required: true},
            eventList: {type: Array, required: true},
            mapEvent: {type: Object},
            hCallList: {type: Array, required: true}
        },
        components: {
            ComTop,
            TripInformation,
            TripCollision,
            ComButtons,
            CallList
        },
        mounted() {
            this.getEndGpsAddress(this.endAddressGps.latitude, this.endAddressGps.longitude);
            this.getStartGpsAddress(this.startAddressGps.latitude, this.startAddressGps.longitude);
        },
        data() {
            return {
                activeBtn: 'information',
                eventMap: new Map(),
                endAddress: '',
                startAddress: '',
            };
        },
        computed: {
            showCollisionBtn() {
                return this.eventList && this.eventList.length > 0;
            },
            showCallBtn() {
                return this.hCallList && this.hCallList.length > 0;
            }
        },
        watch: {
            endAddressGps(gps) {
                this.getEndGpsAddress(gps.latitude, gps.longitude);
            },
            startAddressGps(gps) {
                this.getStartGpsAddress(gps.latitude, gps.longitude);
            },
            eventList() {
                this.activeBtn = 'information';
            },
            mapEvent(eventInfo) {
                if (eventInfo.eventType === 'hCall' || eventInfo.eventType === 'dCall') {
                    this.activeBtn = 'call';
                    let offsetTop = 0, tag = true;
                    this.hCallList.forEach(item => {
                        if (item.CallId === eventInfo.callId) tag = false;
                        if (tag) offsetTop += (item.Type === 'DCall' ? 141 : 201);
                    });
                    this.$nextTick(() => {
                        this.$refs.callList.$refs.callList.scrollTop = offsetTop;
                    });
                } else {
                    let eventIndex = -1, unpermittedCount = 0;
                    let frontViewItemCount = 0, rearViewItemCount = 0, cViewItemCount = 0, frontAndRearViewItemCount = 0;
                    let vehicleData = parseVehicleModel(this.vehicleInfo);
                    this.eventList.forEach((eventItem, index) => {
                        if (eventItem.EventId === eventInfo.eventId){
                            eventIndex = index;
                        }
                        if (eventIndex === -1) {
                            if (eventItem.Event === 'Unpermitted') {
                                unpermittedCount++;
                            } else {
                                if (!vehicleData.isM820){
                                    if ((eventItem.ImageFPath || eventItem.VideoFPath) && (eventItem.ImageRPath || eventItem.VideoRPath)) {
                                        frontAndRearViewItemCount++;
                                    } else {
                                        if (eventItem.ImageFPath || eventItem.VideoFPath) {
                                            frontViewItemCount++;
                                        }
                                        if (eventItem.ImageRPath || eventItem.VideoRPath) {
                                            rearViewItemCount++;
                                        }
                                    }
                                }else {
                                    if ((eventItem.ImageFPath || eventItem.VideoFPath) &&
                                        (eventItem.ImageRPath || eventItem.VideoRPath) &&
                                        (eventItem.ImageCPath || eventItem.VideoCPath)) {
                                        frontAndRearViewItemCount++;
                                    } else {
                                        if (eventItem.ImageFPath || eventItem.VideoFPath) {
                                            frontViewItemCount++;
                                        }
                                        if (eventItem.ImageRPath || eventItem.VideoRPath) {
                                            rearViewItemCount++;
                                        }
                                        if (eventItem.ImageCPath || eventItem.VideoCPath){
                                            cViewItemCount++;
                                        }
                                    }
                                }

                            }
                        }
                    });
                    if (vehicleData.isM820){
                        //M820特殊处理，因为M820有三组九个摄像头
                        if (eventIndex === -1) return;
                        let itemHeight = 465 + 35 + 170;
                        let frontItemHeight = itemHeight - 170 - 170;
                        let rearItemHeight = itemHeight - 178 - 170;
                        this.activeBtn = 'collision';
                        this.$nextTick(() => {
                            this.$refs.tripCollision.$refs.msgAlarmBox.scrollTop = (unpermittedCount + frontAndRearViewItemCount) * itemHeight
                                //目标item之前的只有前置摄像头有数据的item总高度
                                + frontViewItemCount * frontItemHeight
                                //目标item之前的只有后置摄像头有数据的item总高度
                                + rearViewItemCount * rearItemHeight
                                //目标item之前的只有C摄像头有数据的item总高度
                                + cViewItemCount * rearItemHeight;
                        });

                    }else {
                        if (eventIndex === -1) return;
                        let itemHeight = 465 + 35;
                        let frontItemHeight = itemHeight - 170;
                        let rearItemHeight = itemHeight - 178;
                        this.activeBtn = 'collision';
                        this.$nextTick(() => {
                            this.$refs.tripCollision.$refs.msgAlarmBox.scrollTop = (unpermittedCount + frontAndRearViewItemCount) * itemHeight
                                //目标item之前的只有前置摄像头有数据的item总高度
                                + frontViewItemCount * frontItemHeight
                                //目标item之前的只有后置摄像头有数据的item总高度
                                + rearViewItemCount * rearItemHeight;
                        });
                    }

                }
            }
        },
        methods: {
            getEndGpsAddress(latitude, longitude) {
                this.$Gps.getLocation(latitude, longitude)
                    .then(location => this.endAddress = location)
                    .catch(location => this.endAddress = location);
            },
            getStartGpsAddress(latitude, longitude) {
                this.$Gps.getLocation(latitude, longitude)
                    .then(location => this.startAddress = location)
                    .catch(location => this.startAddress = location);
            },
            handleClose() {
                this.$emit('close');
            },
            handleActiveButtonTypeChange(type) {
                this.activeBtn = type;
                switch (type) {
                    case 'information':
                        this.$refs.tripInformation.resizeHeight()
                        break;
                    case 'collision':
                        this.$refs.tripCollision.resizeHeight();
                        break;
                    case 'call':
                        this.$refs.callList.resizeHeight();
                        break;
                    default:
                        break;
                }
            }
        },
        directives: {
            drag(el, binding) {
                el.onmousedown = (e) => {
                    let elBox = binding.value.that.$refs.ryDragBoxMsg;
                    let oriX = e.pageX;
                    let oriY = e.pageY;
                    let documentWidth = document.getElementById('ryFullScreen').clientWidth;
                    let documentHeight = document.getElementById('ryFullScreen').clientHeight;
                    let leftWidth = document.getElementById('ryLeft').clientWidth;
                    let btnWidth = document.getElementById('ryFleetShowBtn').clientWidth;
                    btnWidth = 0;
                    let bottomHeight = document.getElementById('ryBottom').clientHeight;
                    let dragBoxWidth = documentWidth - leftWidth - btnWidth;
                    let dragBoxHeight = documentHeight - bottomHeight;
                    let selfWidth = el.clientWidth;
                    let selfHeight = el.clientHeight;
                    let curX = elBox.style.right.replace('px', '');
                    let curY = elBox.style.top.replace('px', '');
                    curX = curX === '' ? 60 : parseInt(curX);
                    curY = curY === '' ? 0 : parseInt(curY);
                    document.onmousemove = function (e) {
                        let xOffset = oriX - e.pageX;
                        let yOffset = e.pageY - oriY;
                        let newX = curX + xOffset;
                        let newY = curY + yOffset;
                        newX = newX < 0 ? 0 : newX;
                        newY = newY < 0 ? 0 : newY;
                        newX = newX < (dragBoxWidth - selfWidth) ? newX : (dragBoxWidth - selfWidth);
                        newY = newY < (dragBoxHeight - selfHeight) ? newY : (dragBoxHeight - selfHeight);
                        elBox.style.right = newX + 'px';
                        elBox.style.top = newY + 'px';
                    };
                    document.onmouseup = function () {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .msg-drag-box-v2
        position absolute
        right 60px
        top 15px
        width 320px

        .top-blue-border
            border-left 1px solid $color_blue_first
            border-right 1px solid $color_blue_first

        .grey-border-left
            border-left 1px solid $color_gray_third

        .grey-border-right
            border-right 1px solid $color_gray_third

        .grey-border-bottom
            border-bottom 1px solid $color_gray_third

        .msg-com-alert-msg-history
            position relative
            height 100%
            width 100%
            overflow hidden
</style>
