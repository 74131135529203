<template>
    <div class="call-list ry-scroll" ref="callList">

        <div class="cl-row" v-for="(call, index) in hCallList">
            <div class="cl-head">
                <div v-if="call.Type === 'HCall'">
                    <span class="cl-icon via-microphone"/>{{$t('tripHistory.way2CallFromHeadquarter')}}
                </div>
                <div v-else>
                    <span class="cl-icon via-microphone"/>{{$t('tripHistory.way2CallFromDriver')}}
                </div>
            </div>
            <div class="cl-date-line">
                <span class="cl-icon via-hours"/>
                <span class="cl-datetime">{{ call.Timestamp === null ? $t('globalText.na') : $dateFormat.dateTime(call.Timestamp, mixin_dateTimeFormat, mixin_timezone) }}</span>
                <span class="cl-all-time" :id="'call_list_time_' + call.CallId + '_' + index"> </span>
            </div>
            <div class="cl-address"><span class="cl-icon via-map-marker"/>{{addressList[index].address}}</div>
            <div class="cl-voice" v-if="call.Type === 'HCall'">
                <div class="cl-audio-box">
                    <audio :id="'call_list_audio_'+ call.CallId+'_' + index" :ref="'audio_' + index" controls :src="call.Audio"
                           @play="onPlay(call.CallId, index)"
                           @loadedmetadata="onLoadedMetaData(index, call.CallId)"
                           @error="onAudioLoadError(index, call.CallId)"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "CallList",
        props: {
            autoResize: {
                type: Boolean,
                default: false
            },
            hCallList: {
                type: Array,
                required: true
            }
        },
        beforeMount() {
            this.initAddressList();
        },
        mounted() {
            this.$nextTick(() => {
                this.resizeHeight();
            });
        },
        data() {
            return {
                addressList: [],
                lastPlayingAudio: null,
                lastPlayingId: null,
                test: 99,
            }
        },
        watch: {
            '$store.getters.screen'() {
                this.resizeHeight();
            },
            hCallList() {
                this.initAddressList();
            }
        },
        methods: {
            onPlay(callId, index) {
                let id = 'call_list_audio_'+ callId+'_' + index;
                if (this.lastPlayingAudio && callId !== this.lastPlayingId)
                    this.lastPlayingAudio.pause();
                this.lastPlayingAudio = document.getElementById(id);
                this.lastPlayingId = callId;
            },
            onLoadedMetaData(index, callId) {
                let audioId = 'call_list_audio_' + callId + '_' + index,
                    timeId = 'call_list_time_' + callId + '_' + index;
                let audioComp = document.getElementById(audioId);
                if (audioComp && audioComp.duration) {
                    let totalTime = parseInt(audioComp.duration);
                    let minutes = parseInt(totalTime / 60),
                        seconds = parseInt(totalTime % 60);
                    let timeStr = '';
                    if (minutes) timeStr = minutes + "'";
                    timeStr += seconds + '"';
                    if (timeStr) document.getElementById(timeId).innerText = timeStr;
                }
            },
            onAudioLoadError(index, callId) {
                let name = 'call_list_audio_' + callId + '_' + index;
                document.getElementById(name).style.display = 'none';
            },
            initAddressList() {
                let list = [], newList = [];
                this.hCallList.forEach((eventInfo, index) => {
                    list[index] = {latitude: eventInfo.Latitude, longitude: eventInfo.Longitude, address: ''};
                    newList[index] = {latitude: eventInfo.Latitude, longitude: eventInfo.Longitude, address: ''};
                });
                this.addressList = list;
                this.$Gps.getAllLocation(list).then(result => {
                    result.forEach((location, index) => newList[index].address = location);
                    this.addressList = newList;
                }).catch(errorResult => {
                    errorResult.forEach((location, index) => newList[index].address = location);
                    this.addressList = newList;
                });
            },
            resizeHeight() {
                if (!this.autoResize) return;
                let boxOffsetTop = this.$refs.callList.offsetTop || 98,
                    parentBoxOffsetTop = this.$parent.$refs.ryDragBoxMsg.offsetTop,
                    historyBoxOffsetTop = document.getElementById('contentBox').offsetTop,
                    bodyHeight = document.documentElement.clientHeight,
                    totalOffset = boxOffsetTop + parentBoxOffsetTop + historyBoxOffsetTop,
                    newHeight = bodyHeight - totalOffset;
                if (newHeight < 511)
                    this.$refs.callList.style.height = newHeight + 'px';
                else
                    this.$refs.callList.style.height = '511px';
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .ry-scroll::-webkit-scrollbar
        width: 10px;

    .ry-scroll::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;

    .ry-scroll::-webkit-scrollbar-thumb
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

    .call-list
        cursor default
        background-color #ffffff
        overflow-y auto
        padding-left 12px
        padding-right 12px
        height 476px + 35px

        .cl-icon
            display inline-block
            width 20px

        .cl-row
            padding-top 10px
            border-bottom 1px solid $color_gray_third

            .via-microphone
                color #2eaf9e

            .cl-head, .cl-date-line, .cl-address
                line-height 35px

            .cl-date-line
                .cl-all-time
                    padding-left 20px

            .cl-address
                margin-bottom 15px
                height 40px
                padding-top 5px
                line-height 20px
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

            .cl-voice
                padding 0 0 10px

                .cl-audio-box
                    height 50px
                    background url("../../assets/image/voice_not_found.png") no-repeat
                    background-size 100% 50px

                audio
                    outline-width 0
                    background-color #ffffff
                    width 100%
                    height 100%


</style>
