<template>
    <div class="driver-trip-filter">
        <div class="dtf-info-box">
            <div class="dtf-row">
                <span class="dtf-left">{{$t('tripHistory.totalTime')}}:</span>
                <span class="dtf-right">{{ totalTime }}</span>
            </div>
            <div class="dtf-row">
                <span class="dtf-left">{{$t('tripHistory.totalIdleTime')}}:</span>
                <span class="dtf-right">{{ totalIdleTime }}</span>
            </div>
            <div class="dtf-row">
                <span class="dtf-left">{{$t('tripHistory.distance')}}:</span>
                <span class="dtf-right">{{ distance }} {{ distanceUnit }}</span>
            </div>
            <div class="dtf-row" v-if="avgFuelConsumption !== false">
                <span class="dtf-left">{{$t('tripHistory.avgFuelConsumption')}}:</span>
                <span class="dtf-right" v-if="avgFuelConsumption === false">{{$t('globalText.na')}}</span>
                <span class="dtf-right" v-else>{{ avgFuelConsumption }} {{ UnitKmL }}</span>
            </div>
            <div class="dtf-row" v-if="totalIdleFuelConsumption !== false">
                <span class="dtf-left">{{$t('tripHistory.totalIdleFuelConsumption')}}:</span>
                <span class="dtf-right" v-if="totalIdleFuelConsumption === false">{{$t('globalText.na')}}</span>
                <span class="dtf-right" v-else>{{ totalIdleFuelConsumption }} {{ UnitL }}</span>
            </div>
            <div class="dtf-row" v-if="totalFuelConsumption !== false">
                <span class="dtf-left">{{$t('tripHistory.totalFuelConsumption')}}:</span>
                <span class="dtf-right" v-if="totalFuelConsumption === false">{{$t('globalText.na')}}</span>
                <span class="dtf-right" v-else>{{ totalFuelConsumption }} {{ UnitL }}</span>
            </div>
            <div class="dtf-row">
                <span class="dtf-left">{{$t('tripHistory.avgDriverScore')}}:</span>
                <span class="dtf-right">{{ avgDriverScore }}</span>
            </div>
        </div>
        <div class="dtf-filter-box">
            <div class="dtf-header dtf-collision">
                <span class="dtf-left">
                    <el-checkbox v-model="collisionOption.checked" @change="handleCollisionChange">{{$tc('tripHistory.collisionAlerts', 1)}}<i
                            class="dtf-icon via-car-crash"/></el-checkbox>
                </span>
                <span class="dtf-right">{{ formatCount(collisionCount) }}</span>
            </div>
        </div>
        <div class="dtf-filter-box">
            <div class="dtf-header dtf-collision">
                <span class="dtf-left">
                    <el-checkbox v-model="driverAlertOption.checked" @change="handleDriverAlertChange">{{$tc('tripHistory.driverAlerts', 1)}}<i
                            class="dtf-icon via-Driver-alert"/></el-checkbox>
                </span>
                <span class="dtf-right">{{ formatCount(driverAlertCount) }}</span>
            </div>
        </div>
        <div class="dtf-filter-box" v-if="showParkingAlert">
            <div class="dtf-header dtf-collision">
                <span class="dtf-left">
                    <el-checkbox disabled v-model="parkingAlertOption.checked">{{$tc('tripHistory.parkingAlerts', 1)}}<i
                            class="dtf-icon via-Parking-alert"/></el-checkbox>
                </span>
                <span class="dtf-right">{{ formatCount(parkingAlertCount) }}</span>
            </div>
        </div>
        <div class="dtf-filter-box" v-show="showCheckboxArea(this.callOption.countArr)">
            <div class="dtf-header dtf-call">
                <span class="dtf-left">
                    <el-checkbox :indeterminate="callOption.isIndeterminate"
                                 v-model="callOption.checkAll"
                                 @change="handleCallCheckAllChange">{{$t('tripHistory.way2Call')}}<i
                            class="dtf-icon via-microphone"/></el-checkbox>
                </span>
                <span class="dtf-right dtf-show" @click="showBox.call = !showBox.call">
                    <i v-show="showBox.call" class="via-chevron-down"/>
                    <i v-show="!showBox.call" class="via-chevron-up"/>
                </span>
            </div>
            <div class="dtf-choice-box" v-show="showBox.call">
                <el-checkbox-group v-model="callOption.checkedValue" @change="handleCallCheckedValueChange">
                    <el-checkbox v-for="(name, index) in callOption.allOptions"
                                 v-show="showCheckboxItem(callOption.countArr[index])"
                                 :label="name"
                                 :key="name">
                        <div class="dtf-row">
                            <span class="dtf-left">{{ $t(labelArr[name]) }}:</span>
                            <span class="dtf-right">{{ formatCount(callOption.countArr[index]) }}</span>
                        </div>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="dtf-filter-box" v-show="showCheckboxArea(this.gSensorOption.countArr)">
            <div class="dtf-header dtf-g-sensor">
                <span class="dtf-left">
                    <el-checkbox :indeterminate="gSensorOption.isIndeterminate"
                                 v-model="gSensorOption.checkAll"
                                 @change="handleGSensorCheckAllChange">{{$t('tripHistory.gSensorEvents')}}<i
                            class="dtf-icon via-flag"/></el-checkbox>
                </span>
                <span class="dtf-right dtf-show" @click="showBox.gSensor = !showBox.gSensor">
                    <i v-show="showBox.gSensor" class="via-chevron-down"/>
                    <i v-show="!showBox.gSensor" class="via-chevron-up"/>
                </span>
            </div>
            <div class="dtf-choice-box" v-show="showBox.gSensor">
                <el-checkbox-group v-model="gSensorOption.checkedValue" @change="handleGSensorCheckedValueChange">
                    <el-checkbox v-for="(name, index) in gSensorOption.allOptions"
                                 v-show="showCheckboxItem(gSensorOption.countArr[index])"
                                 :label="name"
                                 :key="name">
                        <div class="dtf-row">
                            <span class="dtf-left">{{ $t(labelArr[name]) }}:</span>
                            <span class="dtf-right">{{ formatCount(gSensorOption.countArr[index]) }}</span>
                        </div>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="dtf-filter-box" v-show="showCheckboxArea(this.adasOption.countArr)">
            <div class="dtf-header dtf-adas">
                <span class="dtf-left">
                    <el-checkbox :indeterminate="adasOption.isIndeterminate"
                                 v-model="adasOption.checkAll"
                                 @change="handleADASCheckAllChange">{{$t('tripHistory.ADASEvents')}}<i
                            class="dtf-icon via-flag"/></el-checkbox>
                </span>
                <span class="dtf-right dtf-show" @click="showBox.adas = !showBox.adas">
                    <i v-show="showBox.adas" class="via-chevron-down"/>
                    <i v-show="!showBox.adas" class="via-chevron-up"/>
                </span>
            </div>
            <div class="dtf-choice-box" v-show="showBox.adas">
                <el-checkbox-group v-model="adasOption.checkedValue" @change="handleADASCheckedValueChange">
                    <el-checkbox v-for="(name, index) in adasOption.allOptions"
                                 v-show="showCheckboxItem(adasOption.countArr[index])"
                                 :label="name"
                                 :key="name">
                        <div class="dtf-row">
                            <span class="dtf-left">{{ $t(labelArr[name]) }}:</span>
                            <span class="dtf-right">{{ formatCount(adasOption.countArr[index]) }}</span>
                        </div>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="dtf-filter-box" v-show="showCheckboxArea(this.dmsOption.countArr)">
            <div class="dtf-header dtf-dms">
                <span class="dtf-left">
                    <el-checkbox :indeterminate="dmsOption.isIndeterminate"
                                 v-model="dmsOption.checkAll"
                                 @change="handleDMSCheckAllChange">{{$t('tripHistory.DMSEvents')}}<i
                            class="dtf-icon via-flag"/></el-checkbox>
                </span>
                <span class="dtf-right dtf-show" @click="showBox.dms = !showBox.dms">
                    <i v-show="showBox.dms" class="via-chevron-down"/>
                    <i v-show="!showBox.dms" class="via-chevron-up"/>
                </span>
            </div>
            <div class="dtf-choice-box" v-show="showBox.dms">
                <el-checkbox-group v-model="dmsOption.checkedValue" @change="handleDMSCheckedValueChange">
                    <el-checkbox v-for="(name, index) in dmsOption.allOptions"
                                 v-show="showCheckboxItem(dmsOption.countArr[index])"
                                 :label="name"
                                 :key="name">
                        <div class="dtf-row">
                            <span class="dtf-left">{{ $t(labelArr[name]) }}:</span>
                            <span class="dtf-right">{{ formatCount(dmsOption.countArr[index]) }}</span>
                        </div>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
    const LABEL_ARR = [
        'Hard Revving', 'Hard Braking', 'Hard Cornering', // G-sensor Events
        'Driver Fatigue', 'Distracted Driving', 'Smoking', 'Phone Usage', // DMS Events
        'LDW', 'FCW', // ADAS Event
        'Collision', 'Driver Alert', 'Parking Alert',
        'Driver', 'Headquarters' // 2 Way Call
    ];
    const LABEL_I18N_ARR = [
        'tripHistory.hardRevving', 'tripHistory.hardBraking', 'tripHistory.hardCornering', // G-sensor Events
        'tripHistory.driverFatigue', 'tripHistory.distractedDriving', 'tripHistory.smoking', 'tripHistory.phoneUsage', // DMS Events
        'tripHistory.LDW', 'tripHistory.FCW', // ADAS Event
        'tripHistory.collision', 'tripHistory.driverAlert', 'tripHistory.parkingAlert',
        'tripHistory.driver', 'tripHistory.headquarters' // 2 Way Call 需要补充字段
    ];
    const VALUE_ARR = [
        'AccelerationCount', 'BrakingCount', 'CorneringCount', // G-sensor Events
        'FatigueCount', 'DistractedCount', 'SmokingCount', 'PhoneCount', // DMS Events
        'LDWCount', 'FCWCount', // ADAS Event
        'CollisionCount'
    ];
    const VALUE_FLAG_ARR = [
        'AccelerationFlag', 'BrakingFlag', 'CorneringFlag', // G-sensor Events
        'FatigueFlag', 'DistractedFlag', 'SmokingFlag', 'PhoneFlag', // DMS Events
        'LDWFlag', 'FCWFlag', // ADAS Event
        'CollisionFlag', 'DriverAlertFlag', 'ParkingAlertFlag',
        'DCallFlag', 'HCallFlag'
    ];
    const
        REVVING_UP = 0, HARD_BRAKING = 1, HARD_CORNERING = 2, // G-sensor Events
        DRIVER_FATIGUE = 3, DISTRACTED_DRIVING = 4, SMOKING = 5, PHONE_USAGE = 6, // DMS Events
        LDW = 7, FCW = 8, // ADAS Event
        COLLISION = 9, DRIVER_ALERT = 10, PARKING_ALERT = 11,
        CALL_DRIVER = 12, CALL_HEADQUARTERS = 13;
    const allGSensorOptions = [REVVING_UP, HARD_BRAKING, HARD_CORNERING];
    const allGSensorCount = [-1, -1, -1];
    const allDMSOptions = [DRIVER_FATIGUE, DISTRACTED_DRIVING, SMOKING, PHONE_USAGE];
    const allDMSCount = [-1, -1, -1, -1];
    const allADASOptions = [LDW, FCW];
    const allADASCount = [-1, -1];
    const all2WayCallOptions = [CALL_DRIVER, CALL_HEADQUARTERS];
    const all2WayCallCount = [-1, -1];

    export default {
        name: "DriverTripFilter",
        props: {
            timestamp: {type: Number, required: true},
            totalTime: {type: String, required: true},
            totalIdleTime: {type: String, required: true},
            distance: {type: String, required: true},
            distanceUnit: {type: String, required: true},
            avgFuelConsumption: {type: [String, Boolean], required: true},
            totalFuelConsumption: {type: [String, Boolean], required: true},
            totalIdleFuelConsumption: {type: [String, Boolean], required: true},
            UnitL: {type: [String], required: true},
            UnitKmL: {type: [String], required: true},
            collisionCount: {type: Number, required: true},
            driverAlertCount: {type: Number, required: true},
            parkingAlertCount: {type: Number, required: true},
            showParkingAlert: {type: Boolean, required: false},
            avgDriverScore: {type: [Number, String], required: true},
            gSensorCount: {
                type: Array, // [REVVING_UP, HARD_BRAKING, HARD_CORNERING]
                required: true
            },
            adasCount: {
                type: Array, // [LDW, FCW]
                required: true
            },
            dmsCount: {
                type: Array, // [DRIVER_FATIGUE, DISTRACTED_DRIVING, SMOKING, PHONE_USAGE]
                required: true
            },
            callCount: {
                type: Array, // [CALL_DRIVER, CALL_HEADQUARTERS]
                // required: true
            }
        },
        beforeMount() {
            if (this.collisionCount > 0) this.collisionOption.count = this.collisionCount;
            if (this.gSensorCount.length === 3) this.gSensorOption.countArr = this.gSensorCount;
            if (this.adasCount.length === 2) this.adasOption.countArr = this.adasCount;
            if (this.dmsCount.length === 4) this.dmsOption.countArr = this.dmsCount;
            if (this.callCount.length === 2) this.callOption.countArr = this.callCount;
        },
        data() {
            return {
                showBox: {
                    gSensor: true,
                    adas: true,
                    dms: true,
                    call: true
                },
                labelArr: LABEL_I18N_ARR, // 未转变为i18n时是：LABEL_ARR
                collisionOption: {
                    checked: true,
                    count: 0
                },
                driverAlertOption: {
                    checked: true,
                    count: 0
                },
                parkingAlertOption: {
                    checked: true,
                    count: 0
                },
                gSensorOption: {
                    allOptions: allGSensorOptions,
                    checkedValue: allGSensorOptions,
                    countArr: allGSensorCount,
                    isIndeterminate: false,
                    checkAll: true,
                },
                adasOption: {
                    allOptions: allADASOptions,
                    checkedValue: allADASOptions,
                    countArr: allADASCount,
                    isIndeterminate: false,
                    checkAll: true,
                },
                dmsOption: {
                    allOptions: allDMSOptions,
                    checkedValue: allDMSOptions,
                    countArr: allDMSCount,
                    isIndeterminate: false,
                    checkAll: true,
                },
                callOption: {
                    allOptions: all2WayCallOptions,
                    checkedValue: all2WayCallOptions,
                    countArr: all2WayCallCount,
                    isIndeterminate: false,
                    checkAll: true,
                },
            }
        },
        watch: {
            timestamp() {
                this.collisionOption = {
                    checked: true,
                    count: 0
                };
                this.gSensorOption = {
                    allOptions: allGSensorOptions,
                    checkedValue: allGSensorOptions,
                    countArr: allGSensorCount,
                    isIndeterminate: false,
                    checkAll: true,
                };
                this.adasOption = {
                    allOptions: allADASOptions,
                    checkedValue: allADASOptions,
                    countArr: allADASCount,
                    isIndeterminate: false,
                    checkAll: true,
                };
                this.dmsOption = {
                    allOptions: allDMSOptions,
                    checkedValue: allDMSOptions,
                    countArr: allDMSCount,
                    isIndeterminate: false,
                    checkAll: true,
                };
                this.emitSelectedValue(true);
            },
            collisionCount(value) {
                if (value > 0) this.collisionOption.count = value;
            },
            gSensorCount(value) {
                if (value.length === 3) this.gSensorOption.countArr = value;
            },
            adasCount(value) {
                if (value.length === 2) this.adasOption.countArr = value;
            },
            dmsCount(value) {
                if (value.length === 4) this.dmsOption.countArr = value;
            },
            callCount(value) {
                if (value.length === 2) this.callOption.countArr = value;
            }
        },
        methods: {
            formatCount(value) {
                if (isNaN(value) || value < 0) return this.$t('globalText.na');
                if (value === 1) return value + ' ' + this.$tc('tripHistory.times', 1);
                return value + ' ' + this.$tc('tripHistory.times', 2);
            },
            showCheckboxItem(value) {
                return !(isNaN(value) || value < 0);
            },
            showCheckboxArea(countArr) {
                let show = false;
                countArr.forEach(count => {
                    if (typeof count === 'number' && count >= 0) show = true;
                });
                return show;
            },
            handleGSensorCheckAllChange(value) {
                this.gSensorOption.checkedValue = value ? this.gSensorOption.allOptions : [];
                this.gSensorOption.isIndeterminate = false;
                this.emitSelectedValue();
            },
            handleGSensorCheckedValueChange(value) {
                let checkedCount = value.length;
                this.gSensorOption.checkAll = checkedCount === this.gSensorOption.allOptions.length;
                this.gSensorOption.isIndeterminate = checkedCount > 0 && checkedCount < this.gSensorOption.allOptions.length;
                this.emitSelectedValue();
            },
            handleADASCheckAllChange(value) {
                this.adasOption.checkedValue = value ? this.adasOption.allOptions : [];
                this.adasOption.isIndeterminate = false;
                this.emitSelectedValue();
            },
            handleCallCheckAllChange(value) {
                this.callOption.checkedValue = value ? this.callOption.allOptions : [];
                this.callOption.isIndeterminate = false;
                this.emitSelectedValue();
            },
            handleADASCheckedValueChange(value) {
                let checkedCount = value.length;
                this.adasOption.checkAll = checkedCount === this.adasOption.allOptions.length;
                this.adasOption.isIndeterminate = checkedCount > 0 && checkedCount < this.adasOption.allOptions.length;
                this.emitSelectedValue();
            },
            handleDMSCheckAllChange(value) {
                this.dmsOption.checkedValue = value ? this.dmsOption.allOptions : [];
                this.dmsOption.isIndeterminate = false;
                this.emitSelectedValue();
            },
            handleDMSCheckedValueChange(value) {
                let checkedCount = value.length;
                this.dmsOption.checkAll = checkedCount === this.dmsOption.allOptions.length;
                this.dmsOption.isIndeterminate = checkedCount > 0 && checkedCount < this.dmsOption.allOptions.length;
                this.emitSelectedValue();
            },
            handleCallCheckedValueChange(value) {
                let checkedCount = value.length;
                this.callOption.checkAll = checkedCount === this.callOption.allOptions.length;
                this.callOption.isIndeterminate = checkedCount > 0 && checkedCount < this.callOption.allOptions.length;
                this.emitSelectedValue();
            },
            handleCollisionChange() {
                this.emitSelectedValue();
            },
            handleDriverAlertChange() {
                this.emitSelectedValue();
            },
            emitSelectedValue(isInitStatus) {
                let allSelectedValue = this.gSensorOption.checkedValue
                    .concat(this.adasOption.checkedValue)
                    .concat(this.dmsOption.checkedValue)
                    .concat(this.callOption.checkedValue);
                if (this.collisionOption.checked) {
                    allSelectedValue.push(COLLISION);
                }
                if (this.driverAlertOption.checked) {
                    allSelectedValue.push(DRIVER_ALERT);
                }
                let allSelectedName = [];
                allSelectedValue.forEach(value => allSelectedName.push(VALUE_FLAG_ARR[value]));
                this.$emit('filter-flag', {isInit: !!isInitStatus, rules: allSelectedName});
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $box-padding-right = 10px
    $box-padding-left = 10px
    .driver-trip-filter
        cursor default

        .dtf-row, .dtf-header
            display flex
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content space-between
            line-height 24px

            > .dtf-left, > .dtf-right
                overflow hidden
                white-space nowrap
                text-overflow ellipsis
                color $color_black_first

            > .dtf-left
                min-width 160px

            > .dtf-right
                text-align right

        .dtf-info-box
            padding-left $box-padding-left
            padding-right $box-padding-right
            padding-bottom 10px

        .dtf-filter-box
            padding-bottom 10px

            >>> .el-checkbox__input.is-checked .el-checkbox__inner,
            >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner
                background-color #ffffff

            >>> .el-checkbox__inner::after
                border-color $color_black_first

            >>> .el-checkbox__input.is-checked .el-checkbox__inner,
            >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner
                border-color $color_black_first

            >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner::before
                background-color $color_black_first
                top 0px
                height 12px
                transform: scale(.8)

            >>> .el-checkbox__input.is-checked + .el-checkbox__label
                color $color_black_first

            >>> .el-checkbox
                display block
                margin-right 0px

            >>> .el-checkbox__input
                width 20px

            >>> .el-checkbox__label
                width calc(100% - 20px)
                padding-left 0px

            .dtf-icon
                display inline-block
                padding-left 8px

            .dtf-header, .dtf-choice-box
                padding-left $box-padding-left
                padding-right $box-padding-right

            .dtf-header
                .dtf-show
                    width 50px
                    cursor: pointer;

            .dtf-collision
                background-color #ffe8e8

                .dtf-icon
                    color red

            .dtf-g-sensor
                background-color #e1efff

                .dtf-icon
                    color rgb(0, 198, 255)

            .dtf-adas
                background-color #fffbe2

                .dtf-icon
                    color rgb(255, 156, 0)

            .dtf-dms
                background-color #ffeffc

                .dtf-icon
                    color rgb(227, 84, 240)

            .dtf-call
                background-color #e1ffef

                .dtf-icon
                    color #2eaf9e
</style>
