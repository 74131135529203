<template>
    <div class="trip-list-box">
        <driver-trip-filter class="tlb-filter-box"
                            v-show="showSummaryBox && tips.show === false"
                            @filter-flag="handleFilterTripFlag"
                            :timestamp="timestamp"
                            :total-time="formatTime(tripSummary.SumTime || 0)"
                            :total-idle-time="formatTime(tripSummary.SumIdleTime || 0)"
                            :distance="convertSummaryKilometerNum"
                            :distance-unit="unitKm"
                            :avg-fuel-consumption="convertFuel(tripSummary.AvgFuelConsumptionRate)"
                            :total-fuel-consumption="convertFuel(tripSummary.SumTotalFuelConsumed)"
                            :total-idle-fuel-consumption="convertFuel(tripSummary.SumIdleTimeFuelConsumption)"
                            :unit-l="unitL"
                            :unit-km-l="unitKML"
                            :avg-driver-score="formateScore(tripSummary.AvgDriverScore)"
                            :collision-count="tripSummary.CollisionCount || 0"
                            :driver-alert-count="tripSummary.DriverAlertCount || 0"
                            :parking-alert-count="tripSummary.ParkingCollisionCount || 0"
                            :show-parking-alert="showParkingAlert"
                            :g-sensor-count="[
                                tripSummary.AccelerationCount,
                                tripSummary.BrakingCount,
                                tripSummary.CorneringCount]"
                            :dms-count="[
                                tripSummary.FatigueCount,
                                tripSummary.DistractedCount,
                                tripSummary.SmokingCount,
                                tripSummary.PhoneCount]"
                            :adas-count="[
                                tripSummary.LDWCount,
                                tripSummary.FCWCount]"
                            :call-count="[
                                tripSummary.DCallCount,
                                tripSummary.HCallCount
                            ]"/>
        <div class="tl-detail" v-show="tips.show === false">
            <div class="tl-group-box" v-for="{date, total, groupIndex, list} in currentTripList" :key="groupIndex">
                <div class="tl-row tl-title" v-if="startDate !== endDate">{{ convertDateStrToLocal(date) }} ({{ total }})</div>
                <div class="tl-row" :class="{'tl-active': currentIndex === trip.index}"
                     v-for="(trip, index) in list"
                     :key="trip.index" @click="handleCurrentIndexChange(groupIndex, index, trip.index)">
                    <!--Trip List Start-->
                    <span v-if="trip.CollisionCount > 0 && trip.type !== 'alert'" class="tl-collision-tips_bak"/>
                    <span v-if="trip.type !== 'alert'" class="tl-left">
                        <span class="tl-icon-box">
                            <i :class="getEventClassName(trip)"/>
                            <div v-if="getEventTitle(trip).length > 0" class="tl-icon-title-box">
                                <div class="tl-title-item" v-for="title in getEventTitle(trip)">{{ title }}</div>
                            </div>
                        </span>
                        <span class="tl-trip-tile" :class="['tl-trip-tile-' + indexLength]"
                              :title="$t('tripHistory.trip') + ' - ' + trip.num">{{$t('tripHistory.trip')}} - {{ trip.num }}</span>
                    </span>
                    <span v-if="trip.type !== 'alert'" :class="['tl-middle-' + indexLength]" class="tl-middle"
                          :title="trip.middleContent">{{ trip.middleContent }}</span>
                    <span v-if="trip.type !== 'alert'" class="tl-right"
                          :title="$dateFormat.time(trip.StartTimestamp, 'HH:mm', mixin_timezone) + ' - ' + $dateFormat.time(trip.Timestamp, 'HH:mm', mixin_timezone)">
                    {{ $dateFormat.time(trip.StartTimestamp, 'HH:mm', mixin_timezone) }} - {{ $dateFormat.time(trip.Timestamp, 'HH:mm', mixin_timezone) }}
                        <span v-if="new Date(trip.Timestamp * 1000).getDate() !== new Date(trip.StartTimestamp * 1000).getDate()" class="tl-trip-more-day">
                            +{{lastDays(trip.StartTimestamp, trip.Timestamp)}}
                        </span>
                    </span>
                    <!--Trip List End-->
                    <!--Parking Alert List Start-->
                    <span v-if="trip.type === 'alert'" class="tl-left tl-left-parking-alert">
                        <span class="tl-icon-box">
                            <i class="via-Parking-alert"/>
                            <div class="tl-icon-title-box">
                                <div class="tl-title-item">{{$t('tripHistory.parkingAlert')}}</div>
                            </div>
                        </span>
                        <span class="tl-trip-tile"
                              :title="$t('tripHistory.parkingAlert') + ' - ' + trip.num">{{$t('tripHistory.parkingAlert')}} - {{ trip.num }}</span>
                    </span>
                    <span v-if="trip.type === 'alert'" class="tl-middle tl-middle-parking-alert"/>
                    <span v-if="trip.type === 'alert'" class="tl-right tl-right-parking-alert"
                          :title="$dateFormat.time(trip.Timestamp)">{{ $dateFormat.time(trip.Timestamp, mixin_timeFormat, mixin_timezone) }}</span>
                    <!--Parking Alert List End-->
                </div>
                <!-- Parking Alert List -->
            </div>
        </div>
        <div class="tlb-no-data-box" v-show="tips.show">{{ i18nTipsConvert(tips.content) }}</div>
    </div>
</template>

<script>
    import {numRound} from '../../assets/js/Util';
    import DriverTripFilter from "./DriverTripFilter";

    const LOADING = 'Loading...';
    const NO_DATA = 'No Data.';

    export default {
        name: "TripList",
        props: {
            tripList: {required: true, type: Array},
            loadingStatus: {type: Object, required: true},
            tripSummary: {type: Object, required: true},
            showParkingAlert: {type: Boolean, required: false},
            startDate: { required: true}, // type: [String, null]
            endDate: { required: true}, // type: [String, null]
        },
        components: {
            DriverTripFilter
        },
        inject: ['convertDateStrToLocal'],
        data() {
            return {
                timestamp: 0,
                currentIndex: 0,
                validTripList: [],
                tips: {
                    show: true,
                    content: LOADING
                }
            };
        },
        computed: {
            currentTripList() {
                let length = this.tripList.length;
                if (length < 1) return this.tripList;
                let newTripList = Object.assign([], this.tripList);
                newTripList.sort((itemA, itemB) => {
                    let tripATimestamp = itemA.StartTimestamp || itemA.Timestamp,
                        tripBTimestamp = itemB.StartTimestamp || itemB.Timestamp;
                    return tripATimestamp - tripBTimestamp;
                });
                let tripGroupList = [],
                    tripItem = null,
                    groupIndex = 0,
                    tripIndex = 0,
                    alertNum = 0,
                    tripNum = 0;
                newTripList.forEach(item => {
                    item.index = tripIndex;
                    tripIndex++;
                    item.type === 'alert' ? alertNum++ : tripNum++;
                    if (tripItem && tripItem.date && tripItem.date === item.Date) {
                        item.num = item.type === 'alert' ? alertNum : tripNum;
                        tripItem.list.push(item);
                        tripItem.total = tripNum + alertNum;
                    } else {
                        if (tripItem) tripGroupList.push(tripItem);
                        tripNum = item.type === 'alert' ? 0 : 1;
                        alertNum = item.type === 'alert' ? 1 : 0;
                        item.num = 1;
                        tripItem = {
                            date: item.Date,
                            groupIndex: groupIndex,
                            total: 1,
                            list: [item]
                        };
                        groupIndex++;
                    }
                });
                if (tripItem && tripGroupList.length > 0)
                    tripGroupList.push(tripItem);
                if (tripItem && tripGroupList.length === 0)
                    tripGroupList.push(tripItem);

                tripGroupList.forEach(items => {
                    items.list.forEach(item => {
                        //let temp = new Date(item.Timestamp * 1000).getDate() - new Date(item.StartTimestamp * 1000).getDate();
                        var date1 = new Date(item.StartTimestamp * 1000);  //开始时间
                        var date2 = new Date(item.Timestamp * 1000);    //结束时间
                    })
                });
                return tripGroupList;
            },
            showSummaryBox() {
                let show = false;
                if (this.tripList) {
                    this.tripList.forEach(() => {
                        show = true;
                    })
                }
                return show;
            },
            convertSummaryKilometerNum() {
                let value = this.$unit.convertKilometerUnit(this.tripSummary.SumDistance || 0);
                return numRound(value, 1);
            },
            unitL() {
                return this.$unit.getLUnit(this);
            },
            unitKML() {
                return this.$unit.getKmLUnit(this);
            },
            unitKm() {
                return this.$unit.getKmUnit(this);
            },
            indexLength() {
                let length = this.validTripList.length;
                return (length + '').length;
            }
        },
        watch: {
            loadingStatus(loading) {
                if (loading.startLoad) {
                    this.tips.show = true;
                    this.tips.content = LOADING;
                }
            },
            tripList() {
                this.timestamp = new Date().getTime();
                this.validTripList = this.currentTripList;
                if (this.validTripList && this.validTripList.length === 0) {
                    this.tips.show = true;
                    this.tips.content = NO_DATA;
                } else {
                    this.tips.show = false;
                    this.tips.content = LOADING;
                }
                this.currentIndex = 0;
                if (this.showSummaryBox) {
                    let tripInfo = null;
                    this.currentTripList.forEach(({list: trip}) => {
                        if (tripInfo === null) {
                            tripInfo = trip[0];
                        }
                    });
                    if (tripInfo) this.handleCurrentIndexChange(0, 0, 0);
                }
            },
        },
        methods: {
            i18nTipsConvert(content) {
                let newContent = '';
                switch (content) {
                    case LOADING:
                        newContent = this.$t('globalText.loading');
                        break;
                    case NO_DATA:
                        newContent = this.$t('globalText.noData');
                        break;
                    default:
                        newContent = this.$t('globalText.loading');
                        break;
                }
                return newContent;
            },
            getEventClassName(trip) {
                let num = 0, className = '';
                if (trip.CollisionCount && trip.CollisionCount > 0) num += 1;
                if (trip.DriverAlertCount && trip.DriverAlertCount > 0) num += 2;
                if (trip.UnpermittedDriver && trip.UnpermittedDriver === 1) num += 5;
                if (num === 0) return [];
                if (num > 5 || num === 3) return ['via-warning'];
                switch (num) {
                    case 0:
                        className = '';
                        break;
                    case 1:
                        className = 'via-car-crash';
                        break;
                    case 2:
                        className = 'via-Driver-alert';
                        break;
                    case 5:
                        className = 'via-Unpermitted';
                        break;
                    default:
                        className = 'via-warning';
                        break;
                }
                return [className, 'tl-waning-icon'];
            },
            getEventTitle(trip) {
                let title = [];
                let collisionAlert = this.$t('tripHistory.collisionAlert'),
                    driverAlert = this.$t('tripHistory.driverAlert'),
                    unpermittedDriver = this.$t('tripHistory.unpermittedDriver');
                if (trip.CollisionCount && trip.CollisionCount > 0) title.push(collisionAlert);
                if (trip.DriverAlertCount && trip.DriverAlertCount > 0) title.push(driverAlert);
                if (trip.UnpermittedDriver && trip.UnpermittedDriver === 1) title.push(unpermittedDriver);
                return title;
            },
            formateScore(score) {
                if (!score) score = 0;
                return numRound(score, 1);
            },
            convertFuel(fuel) {
                if (fuel === false || fuel === undefined) return false;
                let value = this.$unit.convertLiterUnit(fuel);
                return numRound(value, 1);
            },
            handleFilterTripFlag(rules) {
                this.$emit('filter-trip-flag-rules', {redrawMap: !rules.isInit, rules: rules.rules});
            },
            handleCurrentIndexChange(groupIndex, listIndex, globalIndex) {
                this.currentIndex = globalIndex;
                if (this.currentTripList[groupIndex] && this.currentTripList[groupIndex].list[listIndex]) {
                    let currentData = this.currentTripList[groupIndex].list[listIndex];
                    if (currentData.type === 'alert') {
                        this.$emit('changeCollision', currentData.EventId, currentData);
                    } else {
                        let tripId = this.currentTripList[groupIndex].list[listIndex].TripId;
                        let tripInfo = this.currentTripList[groupIndex].list[listIndex];
                        this.$emit('changeTrip', tripId, tripInfo);
                    }
                    return;
                }
                this.$emit('changeTrip', null, {});
            },
            formatTime(time) {
                if (typeof time !== 'number') return '00:00:00';
                let second = time % 60;
                let minute = ((time - second) / 60) % 60;
                second = second < 10 ? '0' + second : second;
                let hours = (parseInt(time / 3600));
                minute = minute < 10 ? '0' + minute : minute;
                hours = hours < 10 ? '0' + hours : hours;
                return '' + hours + ':' + minute + ':' + second;
            },
            formatClockTime(time) {
                if (isNaN(time) || time === 0) return this.$t('globalText.na');
                let dateTime = new Date(time);
                let hour = dateTime.getHours();
                hour = hour < 10 ? "0" + hour : hour;
                let minute = dateTime.getMinutes();
                minute = minute < 10 ? '0' + minute : minute;
                return hour + ':' + minute;
            },
            getTimeStr(timestamp) {
                if (isNaN(timestamp) || timestamp === 0) return this.$t('globalText.na');
                let date = new Date(timestamp * 1000);
                let hour = date.getHours();
                let minute = date.getMinutes();
                let seconds = date.getSeconds();
                hour = hour < 10 ? '0' + hour : hour + '';
                minute = minute < 10 ? '0' + minute : minute + '';
                seconds = seconds < 10 ? '0' + seconds : seconds + '';
                return hour + ':' + minute + ':' + seconds;
            },
            numRound(value, point) {
                if (isNaN(value)) return value;
                let numTmp = Math.pow(10, point);
                return Math.round(value * numTmp) / numTmp;
            },
            lastDays(startTimestamp, endTimestamp) {
                let startDate = new Date(startTimestamp * 1000),
                    endDate = new Date(endTimestamp * 1000);
                let startYear = startDate.getFullYear(),
                    startMonth = startDate.getMonth(),
                    startDay = startDate.getDate(),
                    endYear = endDate.getFullYear(),
                    endMonth = endDate.getMonth(),
                    endDay = endDate.getDate();
                if (startYear === endYear && startMonth === endMonth && startDay === endDay)
                    return 0;
                let newStartDate = new Date(startYear, startMonth, startDay),
                    newEndDate = new Date(endYear, endMonth, endDay);
                return (newEndDate.getTime() - newStartDate.getTime())/(24*3600*1000);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color = $color_black_first
    $second-blue = $color_blue_second
    $hover-bg-color = $color_blue_hover
    $layout-border-color = $color_gray_third
    $box-padding-left = 10px
    $box-padding-right = 10px
    .trip-list-box
        color $font-color
        padding 10px 0
        border-top 1px solid $layout-border-color

        .tlb-filter-box
            border-bottom 1px solid $layout-border-color

        .tlb-no-data-box
            text-align center
            padding-top 30px
            cursor default

        > .tl-summary
            font-weight 600
            cursor default
            padding-bottom 5px
            padding-left $box-padding-left
            padding-right $box-padding-right
            border-bottom 1px solid $layout-border-color

            > .tl-row
                position relative
                padding 0 0 5px
                display flex
                justify-content space-between

                > .tl-empty
                    right 20px !important
                    width 85px !important

                > .tl-left
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                    max-width 170px

                > .tl-right
                    text-align right
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                    width 105px
                    position absolute
                    right 0
                    top 0

                    > .tl-flag
                        display inline-block
                        width 20px
                        text-align right

        > .tl-detail
            > .tl-group-box
                border-bottom 1px solid $layout-border-color

                > .tl-title
                    cursor default !important
                    border-bottom 1px solid $layout-border-color

                > .tl-title:hover
                    background-color rgba(0, 0, 0, 0) !important

                > .tl-active
                    background-color $second-blue !important
                    color $color_white !important
                    z-index 0

                    .tl-trip-more-day
                        color #ffffff !important

                > .tl-row
                    line-height 16px
                    position relative
                    cursor pointer
                    padding-left $box-padding-left
                    padding-right $box-padding-right
                    padding-top 10px
                    padding-bottom 10px

                    > .tl-collision-tips
                        z-index -1
                        position absolute
                        left 5px
                        top 5px
                        display inline-block
                        width 10px
                        height 10px
                        border-radius 50%
                        background-color $color_collision

                    > .tl-left .tl-trip-tile, > .tl-middle, > .tl-right
                        display inline-block
                        white-space nowrap
                        text-overflow ellipsis
                        overflow hidden

                    > .tl-left
                        line-height 16px
                        width_bak 83px
                        display inline-block

                        .tl-trip-tile
                            width 65px

                        .tl-trip-tile-1
                            width 58px

                        .tl-trip-tile-2
                            width 68px

                        .tl-trip-tile-3
                            width 78px

                        .tl-trip-tile-4
                            width 88px

                        .tl-trip-tile-5, .tl-trip-tile-6, .tl-trip-tile-7
                            width 98px

                        .tl-icon-box
                            position relative
                            display inline-block
                            width 18px

                            .tl-icon-title-box
                                cursor default
                                z-index 3000
                                display none
                                color $font-color
                                background-color rgba(255, 255, 255, 1)
                                border-radius 4px
                                padding 5px
                                border 1px solid #4c4c4c99
                                position absolute
                                left 15px
                                bottom 20px

                                .tl-title-item
                                    line-height 20px
                                    white-space nowrap

                            .tl-waning-icon
                                color red

                        .tl-icon-box:hover
                            .tl-icon-title-box
                                display block

                    > .tl-middle
                        width calc(100% - 168px)

                    > .tl-middle-1
                        width calc(100% - 166px)

                    > .tl-middle-2
                        width calc(100% - 176px)

                    > .tl-middle-3
                        width calc(100% - 186px)

                    > .tl-middle-4
                        width calc(100% - 196px)

                    > .tl-middle-5, > .tl-middle-6, > .tl-middle-7
                        width calc(100% - 206px)

                    > .tl-right
                        width 85px
                        text-align right

                        .tl-trip-more-day
                            font-size 10px
                            position absolute
                            bottom 12px
                            right 1px
                            color red

                    > .tl-left-parking-alert
                        width calc(100% - 60px)

                        .tl-trip-tile
                            width calc(100% - 18px)

                    > .tl-middle-parking-alert
                        width 0

                    > .tl-right-parking-alert
                        width 60px

                    .via-warning
                        color red

                > .tl-row:hover
                    background-color $hover-bg-color
                    color $font-color
                    z-index 0
</style>
