<template>
    <com-layout class="history-main-layout" ref="historyLayout">
        <template v-slot:map>
            <com-leaflet-map
                    type="google"
                    :zoom="mapOptions.zoom"
                    :zoom-center="mapOptions.zoomCenter"
                    :center="mapOptions.center"
                    :revving-up-flags="mapOptions.revvingUp"
                    :hard-braking-flags="mapOptions.hardBraking"
                    :hard-cornering-flags="mapOptions.hardCornering"
                    :l-d-w-flags="mapOptions.LDW"
                    :f-c-w-flags="mapOptions.FCW"
                    :driver-fatigue-flags="mapOptions.driverFatigue"
                    :distracted-driving-flags="mapOptions.distractedDriving"
                    :smoking-flags="mapOptions.smoking"
                    :phone-usage-flags="mapOptions.phoneUsage"
                    :path="mapOptions.historyPath"
                    :boundary-path="mapOptions.boundaryPath"
                    :start="mapOptions.startLocation"
                    :end="mapOptions.endLocation"
                    :collisions="mapOptions.collisionsCars"
                    :driver-alert="mapOptions.driverAlert"
                    :parking-alert="mapOptions.parkingAlert"
                    :unpermitted-driver="mapOptions.unpermittedDriver"
                    :target-collision="mapOptions.targetEvent"
                    :driver-call-flag="mapOptions.driverCall"
                    :headquarter-call-flag="mapOptions.headquarterCall"
                    @driverCallEvent="handleMapEvent"
                    @headquarterCallEvent="handleMapEvent"
                    @collisionEvent="handleMapEvent"
                    @parkingAlertEvent="handleMapEvent"
                    @driverAlertEvent="handleMapEvent"
                    @unpermittedDriverEvent="handleMapEvent"
                    @closeAlertBoxFromCollision_bak="closeTripAlertBox"/>
        </template>
        <template v-slot:left>
            <route-left-box
                    ref="leftBox"
                    :start-date="startDate"
                    :end-date="endDate"
                    :loading-status="loadingStatus"
                    :category="category"
                    :alert-category="categoryValue"
                    :trip-summary="tripSummary.data"
                    :trip-driver-list="tripDriver.list"
                    :trip-fleet-list="tripFleet.list"
                    :trip-vehicle-list="tripVehicle.list"
                    :trip-event-list="tripEvent.list"
                    :event-from-map="tripEvent.eventFromMap"
                    :api-vehicle="apiVehicleList"
                    :route-id="routeId"
                    :is-show-parking-alert="isShowParkingAlert"
                    @trip-driver-selected="handleTripDriverSelected"
                    @trip-vehicle-selected="handleTripVehicleSelected"
                    @trip-fleet-selected="handleTripFleetSelected"
                    @alert-collision-selected="handleAlertCollisionSelected"
                    @alert-collision-selected-from-vehicle-list="handleAlertCollisionSelectedFromVehicleList"
                    @filter-driver-trip-flag-rules="handleFilterDriverTripFlagRules"/>
        </template>

        <com-alert-message-history-v2
                :map-event="alertMessageOption.currAlertEvent"
                :trip-info="alertMessageOption.data"
                :driver-info="alertMessageOption.driver"
                :fleet-info="currentFleet"
                :vehicle-info="currentVehicle"
                :end-address-gps="{latitude: alertMessageOption.data.Latitude, longitude: alertMessageOption.data.Longitude}"
                :start-address-gps="{latitude: alertMessageOption.data.StartLatitude, longitude: alertMessageOption.data.StartLongitude}"
                :event-list="alertMessageOption.eventList"
                :h-call-list="alertMessageOption.hCallList"
                v-if="alertMessageOption.show"
                @close="closeTripAlertBox"/>
        <collision-info-alert-box
                :address-gps="{latitude: collisionInfoAlertBoxOption.data.Latitude, longitude: collisionInfoAlertBoxOption.data.Longitude}"
                :end-address-gps="{latitude: collisionInfoAlertBoxOption.endLatitude, longitude: collisionInfoAlertBoxOption.endLongitude}"
                :end-timestamp="collisionInfoAlertBoxOption.endTimestamp"
                :driver-picture-path="collisionInfoAlertBoxOption.driverPicturePath"
                :collision-info="collisionInfoAlertBoxOption.data"
                :driver-info="collisionInfoAlertBoxOption.driver"
                :fleet-info="currentFleetForCollision"
                :vehicle-info="collisionInfoAlertBoxOption.vehicle"
                v-if="collisionInfoAlertBoxOption.show"
                @close="closeCollisionAlertBox"/>
    </com-layout>
</template>

<script>
import RouteLeftBox from '../../components/monitor/RouteLeftBox';
import ComLayout from '../../components/monitor/ComLayout';
import ComAlertMessageHistoryV2 from '../../components/monitor/ComAlertMessageHistoryV2';
import CollisionInfoAlertBox from "../../components/monitor/CollisionInfoAlertBox";
import ComLeafletMap from '../../components/monitor/ComLeafletMap';
import bus from "../../utils/bus";
import {collisionVideoImageKeyConversion, isArrayFn, numRound} from "../../assets/js/Util";

import Constant from '../../components/route/Constant';

let {
        API_VEHICLE_DATA, API_DRIVER_DATA, API_FLEET_DATA,
        DRIVER_VALUE, VEHICLE_VALUE, ALERT_VALUE, FLEET_VALUE,
        A_COLLISION_VALUE, A_PARKING_MODE_COLLISION_VALUE, A_DRIVER_ALERT_VALUE, A_UNPERMITTED_DRIVER_VALUE, A_ALL_VALUE,
        DATE_DAYS_LIST,
        CHANGE_ALL_DATA,
        CLEAR_ALL_HISTORY_DATA
    } = Constant;

    export default {
        name: "Index",
        components: {
            // ComBottomTable,
            RouteLeftBox,
            ComLayout,
            ComAlertMessageHistoryV2,
            ComLeafletMap,
            CollisionInfoAlertBox
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (vm.pageCacheInfo.created) vm.initCreated();
                vm.$nextTick(() => {
                    vm.$refs.leftBox.$refs.left.$refs.leftMiddleContentBox.scrollTop = vm.leftBoxScrollTop;
                });
            });
        },
        beforeRouteLeave(to, from, next) {
            this.leftBoxScrollTop = this.$refs.leftBox.$refs.left.$refs.leftMiddleContentBox.scrollTop;
            next();
        },
        created() {
            this.initCreated();
            let companyInfo = localStorage.getItem("companyLocation") ? JSON.parse(localStorage.getItem("companyLocation")) : null;
            this.mapOptions.center = {
                lng: companyInfo ? (companyInfo.lng ? companyInfo.lng : 121.59154): 121.59154,
                lat: companyInfo ? (companyInfo.lat ? companyInfo.lat : 24.999): 24.999}
        },
        beforeDestroy() {
            bus.$emit('trip-history-filter-condition', {
                type: CLEAR_ALL_HISTORY_DATA,
                data: {}
            });
        },
        data() {
            return {
                isShowParkingAlert: true,
                pageCacheInfo: {
                    created: null, // true 表示需要重新执行操作，null表示不需要重新执行操作
                    filterParams: null, // null表示不需要重新执行操作
                },
                leftBoxScrollTop: 0,
                alertMessageOption: {
                    show: false,
                    data: {},
                    driver: {},
                    eventList: [],
                    currAlertEvent: {}, // 当点击地图的碰撞图标时修改此值
                    hCallList: [],
                    currHcall: {}, // 当点击地图的碰撞图标时修改此值
                },
                collisionInfoAlertBoxOption: {
                    show: false,
                    data: {},
                    driver: {},
                    fleet: {},
                    vehicle: {},
                    endLatitude: null,
                    endLongitude: null,
                    endTimestamp: null,
                    driverPicturePath: ''
                },
                mapOptions: {
                    zoom: 12,
                    zoomCenter: {value: null, center: {lat: 0, lng: 0}}, // 用于修改地图的zoom
                    center: {lat: 24.999, lng: 121.59154},
                    revvingUp: [],
                    hardBraking: [],
                    hardCornering: [],
                    LDW: [],
                    FCW: [],
                    driverFatigue: [],
                    distractedDriving: [],
                    smoking: [],
                    phoneUsage: [],
                    historyPath: [],
                    driverCall: [],
                    headquarterCall: [],
                    boundaryPath: [],
                    boundaryPathTmpData: [], // 只用于记录边界的值，不会自动更新地图
                    startLocation: {},
                    endLocation: {},
                    collisionsCars: [],
                    parkingAlert: [],
                    driverAlert: [],
                    unpermittedDriver: [],
                    targetCollisionCar: {
                        eventId: null,
                        center: null
                    },
                    filterDriverTripRules: []
                },
                apiFleetList: {}, // 从api获取到的fleet信息
                apiFleetListAjaxStatus: false,
                apiVehicleList: [], // 从api获取到的vehicle信息
                apiVehicleListAjaxStatus: false,
                apiDriverList: [], // 从api获取到的driver信息
                apiDriverListAjaxStatus: false,
                category: '',
                categoryValue: '',
                startDate: null,
                endDate: null,
                routeId: '', // 保存通过$route获取到的id参数
                loadingStatus: { // 用于显示Loading提示使用
                    startLoad: false,
                    category: '',
                    categoryValue: '',
                    startDate: '',
                    endDate: ''
                },
                tripSummary: {
                    apiDataMap: new Map(), // 从api获取到的原始的数据的map
                    data: {}, // 当前的summary
                },
                tripDriver: {
                    apiDataMap: new Map(), // 从api获取到的原始数据的map,key根据month来存储数据
                    list: [], // 特定日期的Trip列表
                    currTripId: null,
                    currTripInfo: {}, // 当前选中的trip信息
                },
                tripFleet: {
                    apiDataMap: new Map(), // 从api获取到的原始数据的map,key根据month来存储数据
                    list: [], // 特定日期的Trip列表
                    currTripId: null,
                    currTripInfo: {}, // 当前选中的trip信息
                },
                tripVehicle: {
                    apiDataMap: new Map(), // 从api获取到的原始数据的map,key根据month来存储数据
                    list: [], // 特定日期的Trip列表
                    currTripId: null,
                    currTripInfo: {}, // 当前选中的trip信息
                },
                tripDetail: {
                    apiDataMap: new Map(), // 从api获取到的原始数据的map,key根据tripId来存储数据
                    currInfo: null, // 当前选中的trip detail
                },
                tripEvent: {
                    apiDataMap: new Map(), // 从api获取到的原始数据的map,key根据month来存储数据
                    list: [], // 特定日期的trip event
                    eventFromMap: {},
                    currInfo: null, // 当前选中的trip event
                },
                tripHcall: {
                    apiDataMap: new Map(), // 从api获取到的原始数据的map,key根据tripId来存储数据
                    currInfo: null, // 当前选中trip的hcall列表
                },
                fleetAPIData: new Map(),
                vehicleAPIData: new Map()
            };
        },
        computed: {
            unit4Speed() {
                return this.$unit.getKmHrUnit(this);
            },
            currentFleet() {
                let fleetInfo = {};
                if (this.apiFleetList.Sub && this.alertMessageOption.data.FleetId) {
                    this.apiFleetList.Sub.forEach(fleet => {
                        if (fleet.Id === this.alertMessageOption.data.FleetId)
                            fleetInfo = fleet;
                    });
                }
                return fleetInfo;
            },
            currentFleetForCollision() {
                let fleetInfo = {};
                if (this.apiFleetList.Sub && this.collisionInfoAlertBoxOption.data.FleetId) {
                    this.apiFleetList.Sub.forEach(fleet => {
                        if (fleet.Id === this.collisionInfoAlertBoxOption.data.FleetId)
                            fleetInfo = fleet;
                    });
                }
                return fleetInfo;
            },
            currentVehicle() {
                let vehicleInfo = {};
                this.apiVehicleList.forEach(item => {
                    if (item.Id === this.alertMessageOption.data.VehicleId) {
                        vehicleInfo = item;
                    }
                });
                if (vehicleInfo.Id && this.alertMessageOption.data.VehicleId)
                    vehicleInfo.Id = this.alertMessageOption.data.VehicleId;
                return vehicleInfo;
            }
        },
        watch: {
            globalMixinObj() {
                for (let key in this.mapOptions) {
                    if (Array.isArray(this.mapOptions[key])) {
                        this.mapOptions[key].forEach(item => {
                            if (typeof item === 'object' && !Array.isArray(item) && item.time && item.timestamp) {
                                item.time = this.$dateFormat.dateTime(item.timestamp, this.mixin_dateTimeFormat, this.mixin_timezone);
                            }
                        });
                        this.mapOptions[key] = Object.assign([], this.mapOptions[key]);
                    }
                }
            },
            unit4Speed() { // 用于单位更改是自动重绘地图
                this.redrawMap();
            },
            '$i18n.locale'() {
                this.redrawMap();
            },
            routeAlertEventInfo(info) {
                if (info && info.params) this.handleRouteParams(info.params);
            }
        },
        methods: {
            redrawMap() {
                switch (this.category) {
                    case DRIVER_VALUE:
                        this.handleTripDriverSelected(this.tripDriver.currTripId, this.tripDriver.currTripInfo);
                        break;
                    case VEHICLE_VALUE:
                        this.handleTripVehicleSelected(this.tripVehicle.currTripId, this.tripVehicle.currTripInfo);
                        break;
                    case FLEET_VALUE:
                        this.handleTripFleetSelected(this.tripFleet.currTripId, this.tripFleet.currTripInfo);
                        break;
                    case ALERT_VALUE:
                        this.handleTripEventListForMap(this.tripEvent.list);
                        if (this.tripEvent.currInfo)
                            this.tripEvent.currInfo.redrawMap = true;
                        this.handleAlertCollisionSelected(this.tripEvent.currInfo);
                        break;
                    default:
                        break;
                }
            },
            initCreated() {
                // bus是从顶部的driver、vehicle及日历组件跑出的
                bus.$on('trip-history-filter-action', this.handleFilterAction);
                let initParams = {
                    category: ALERT_VALUE,
                    categoryValue: A_COLLISION_VALUE,
                    startDate: this.formatDate(new Date()),
                    endDate: this.formatDate(new Date())
                };
                let routeParams = this.getRouteParams();
                if (routeParams) {
                    let {category, categoryValue, date, id} = routeParams;
                    this.routeId = id;
                    this.categoryValue = categoryValue;
                    this.category = category;
                    this.startDate = date;
                    this.endDate = date;
                } else {
                    this.category = initParams.category;
                    this.categoryValue = initParams.categoryValue;
                    this.startDate = initParams.startDate;
                    this.endDate = initParams.endDate;
                }
                let fleetListPromise = this.getFleetList();
                let vehicleListPromise = this.getVehicleList();
                let driverListPromise = this.getDriverList();
                let callback = () => {
                    this.pageCacheInfo.created = null;
                    if (routeParams)
                        this.handleRouteParams(routeParams, true);
                    else
                        this.handleNoRouteParams(initParams);
                };
                Promise.all([fleetListPromise, vehicleListPromise, driverListPromise])
                    .then(() => callback())
                    .catch((error) => {
                        this.pageCacheInfo.created = true;
                        if (error.code !== 6001)
                            callback();
                    });
            },
            getRouteParams() {
                if (
                    !this.$route.params.category
                    && !this.$route.params.categoryValue
                    && !this.$route.params.date
                    && !this.$route.params.id
                ) return false;
                let category = this.$route.params.category,
                    categoryValue = this.$route.params.categoryValue,
                    date = this.$route.params.date,
                    id = this.$route.params.id;
                return {category, categoryValue, date, id};
            },
            handleRouteParams(params, isFirst = false) {
                let {category, categoryValue, date, id} = params;
                if (!category && !categoryValue && !date && !id) return;
                if (
                    isFirst
                    && (
                        this.category !== category
                        || this.categoryValue !== categoryValue
                        || this.startDate !== date
                        || this.endDate !== date
                        || this.routeId !== id
                    )
                ) return;
                bus.$emit('trip-history-filter-condition', {
                    type: CHANGE_ALL_DATA,
                    data: {category, categoryValue, startDate: date, endDate: date}
                });
                let data = {category, cateValue: categoryValue, startDate: date, endDate: date};
                this.handleFilterAction({type: 'params', data: data});
                this.routeId = id;
            },
            handleNoRouteParams(params) {
                if (
                    this.category !== params.category
                    || this.categoryValue !== params.categoryValue
                    || this.startDate !== params.startDate
                    || this.endDate !== params.endDate
                ) return;
                bus.$emit('trip-history-filter-condition', {
                    type: CHANGE_ALL_DATA,
                    data: params
                });
                let data = {
                    category: params.category,
                    cateValue: params.categoryValue,
                    startDate: params.startDate,
                    endDate: params.endDate
                };
                this.handleFilterAction({type: 'params', data: data});
            },
            handleFilterAction(action) {
                switch (action.type) {
                    case 'params':
                        this.handleParamsAction(action.data);
                        break;
                    case 'alerts-jump':
                        break;
                    default:
                        console.error('invalid bus action');
                        break;
                }
            },
            handleParamsAction(params) {
                const isLastParams = (args) => {
                    let [cateValue, startDate, endDate] = args;
                    return this.categoryValue === cateValue
                        && this.startDate === startDate
                        && this.endDate === endDate;
                };
                this.pageCacheInfo.filterParams = params;
                this.clearCurrentTripId();
                this.clearMap();
                this.closeCollisionAlertBox();
                this.closeTripAlertBox();
                let {category, cateValue, startDate, endDate} = params;
                this.category = category;
                this.categoryValue = cateValue;
                this.startDate = startDate;
                this.endDate = endDate;
                this.routeId = '';
                switch (category) {
                    case DRIVER_VALUE:
                        this.initTripDriver(cateValue, startDate, endDate)
                            .then(args => {
                                if (isLastParams(args))
                                    this.pageCacheInfo.filterParams = null;
                            })
                            .catch(args => {
                            });
                        break;
                    case VEHICLE_VALUE:
                        this.initTripVehicle(cateValue, startDate, endDate)
                            .then(args => {
                                if (isLastParams(args))
                                    this.pageCacheInfo.filterParams = null;
                            })
                            .catch(args => {
                            });
                        break;
                    case ALERT_VALUE:
                        this.initAlertEvent(cateValue, startDate, endDate)
                            .then(args => {
                                if (isLastParams(args))
                                    this.pageCacheInfo.filterParams = null;
                            })
                            .catch(args => {
                            });
                        break;
                    case FLEET_VALUE:
                        this.initTripFleet(cateValue, startDate, endDate)
                            .then(args => {
                                if (isLastParams(args))
                                    this.pageCacheInfo.filterParams = null;
                            })
                            .catch(args => {
                            });
                        break;
                    default:
                        this.pageCacheInfo.filterParams = null;
                        break;
                }
            },
            openLoadingStatus() {
                this.loadingStatus = {
                    startLoad: !!(this.category && (this.categoryValue || this.categoryValue === 0 || this.categoryValue === '0') && this.startDate && this.endDate),
                    category: this.category,
                    categoryValue: this.categoryValue,
                    startDate: this.startDate,
                    endDate: this.endDate
                };
            },
            initTripDriver(driverId, startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [driverId, startDate, endDate];
                    if (!!driverId === false || !!startDate === false || !!endDate === false) {
                        this.tripDriver.list = [];
                        resolve(params);
                        return;
                    }
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    let tripSummaryPromise = this.getTripDriverSummary(driverId, startDate, endDate);
                    let tripDriverPromise = this.getTripDriverData(driverId, startDate, endDate);
                    Promise.all([tripDriverPromise, tripSummaryPromise]).then(([tripDriver, tripSummary]) => {
                        if (
                            this.category !== DRIVER_VALUE
                            || this.categoryValue !== driverId
                            || this.startDate !== startDate
                            || this.endDate !== endDate
                        ) {
                            resolve(params);
                        } else {
                            this.tripDriver.list = Object.assign([], tripDriver);
                            this.tripSummary.data = Object.assign({}, tripSummary);
                            resolve(params);
                        }
                    }).catch(() => {
                        reject(params);
                        console.log('Failed to get trip Driver');
                    });
                };
                return new Promise(promise);
            },
            initTripFleet(fleetId, startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [fleetId, startDate, endDate];
                    if (!!fleetId === false || !!startDate === false || !!endDate === false) {
                        this.tripFleet.list = [];
                        resolve(params);
                        return;
                    }
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    let driverListPromise = this.getDriverList();
                    let fleetTripPromise = this.getTripFleetData(fleetId, startDate, endDate);
                    let tripSummaryPromise = this.getTripFleetSummary(fleetId, startDate, endDate);
                    let parkingAlertPromise = this.getParkingAlertListByFleetId(startDate, endDate, fleetId);
                    this.getFleetListData(fleetId);//获取车队信息判断子组件是否显示parking-alert选项
                    Promise.all([fleetTripPromise, driverListPromise, tripSummaryPromise, parkingAlertPromise])
                        .then(([tripList, driverList, tripSummary, parkingAlert]) => {
                            if (
                                this.category !== FLEET_VALUE
                                || this.categoryValue !== fleetId
                                || this.startDate !== startDate
                                || this.endDate !== endDate
                            ) {
                                resolve(params);
                            } else {
                                let driverListMap = new Map();
                                driverList.forEach(driverInfo => {
                                    driverListMap.set(driverInfo.Id, `${driverInfo.FirstName} ${driverInfo.LastName}`);
                                });
                                this.tripFleet.list = Object.assign([], tripList);
                                this.tripFleet.list.forEach(tripInfo => {
                                    tripInfo.type = 'trip';
                                    if (driverListMap.has(tripInfo.DriverId))
                                        tripInfo.driverFullName = driverListMap.get(tripInfo.DriverId);
                                });
                                parkingAlert.forEach(item => {
                                    item.type = 'alert';
                                    this.tripFleet.list.push(item);
                                });
                                this.tripSummary.data = Object.assign({}, tripSummary);
                                resolve(params);
                            }
                        })
                        .catch(() => {
                            reject(params);
                            console.log('Failed to get trip vehicle');
                        });
                };
                return new Promise(promise);
            },
            getFleetListData(fleetId){
                if (this.fleetAPIData && this.fleetAPIData.get(fleetId)) {
                    this.setParkingAlertStatus(this.fleetAPIData.get(fleetId))
                }else {
                    this.$serverApi.fleet.getFleetData(fleetId, data => {
                        if (data && data.Vehicles) {
                            this.fleetAPIData.set(fleetId, data);
                            this.setParkingAlertStatus(data)
                        }else {
                            this.isShowParkingAlert = true;
                        }
                    }, () => {
                        this.isShowParkingAlert = true;
                    });
                }

            },
            setParkingAlertStatus(data){
                let vehicleList = data.Vehicles;
                for (let i = 0; i < vehicleList.length; i++) {
                    if (!vehicleList[i].Device || this.getDeviceModel(vehicleList[i].Device) !== 'M810') {
                        //车队中只要有一辆车绑定的Device不是M810，就显示parkingAlert选项
                        this.isShowParkingAlert = false;
                        return;
                    }
                }
            },
            getDeviceModel(deviceInfo){
                let model = deviceInfo.Model || deviceInfo.MainModel || "";
                if (model.indexOf('_') !== -1){
                    return model.split('_')[0];
                }else {
                    return model;
                }

            },
            initTripVehicle(vehicleId, startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [vehicleId, startDate, endDate];
                    if (!!vehicleId === false || !!startDate === false || !!endDate === false) {
                        this.tripVehicle.list = [];
                        resolve(params);
                        return;
                    }
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    let driverListPromise = this.getDriverList();
                    let vehicleTripPromise = this.getTripVehicleData(vehicleId, startDate, endDate);
                    let tripSummaryPromise = this.getTripVehicleSummary(vehicleId, startDate, endDate);
                    let parkingAlertPromise = this.getParkingAlertListByVehicleId(startDate, endDate, vehicleId);
                    this.getVehicleData(vehicleId);
                    Promise.all([vehicleTripPromise, driverListPromise, tripSummaryPromise, parkingAlertPromise])
                        .then(([tripList, driverList, tripSummary, parkingAlert]) => {
                            if (
                                this.category !== VEHICLE_VALUE
                                || this.categoryValue !== vehicleId
                                || this.startDate !== startDate
                                || this.endDate !== endDate
                            ) {
                                resolve(params);
                                return;
                            }
                            let driverListMap = new Map();
                            driverList.forEach(driverInfo => {
                                driverListMap.set(driverInfo.Id, `${driverInfo.FirstName} ${driverInfo.LastName}`);
                            });
                            this.tripVehicle.list = Object.assign([], tripList);
                            this.tripVehicle.list.forEach(tripInfo => {
                                tripInfo.type = 'trip';
                                if (driverListMap.has(tripInfo.DriverId))
                                    tripInfo.driverFullName = driverListMap.get(tripInfo.DriverId);
                            });
                            parkingAlert.forEach(item => {
                                item.type = 'alert';
                                this.tripVehicle.list.push(item);
                            });
                            this.tripSummary.data = Object.assign({}, tripSummary);
                            resolve(params);
                        })
                        .catch(() => {
                            reject(params);
                            console.log('Failed to get trip vehicle');
                        });
                };
                return new Promise(promise);
            },
            getVehicleData(vehicleId){
                if (this.vehicleAPIData && this.vehicleAPIData.get(vehicleId)) {
                    let data = this.vehicleAPIData.get(vehicleId);
                    this.isShowParkingAlert = !data.isM810;
                }else {
                    this.$serverApi.vehicles.getVehicle(vehicleId,data =>{
                        if (data) {
                            this.vehicleAPIData.set(vehicleId, data);
                        }
                        this.isShowParkingAlert = !data.isM810;
                    },()=>{
                        this.isShowParkingAlert = true;
                    })
                }

            },
            initAlertEvent(cateValue, startDate, endDate) {
                this.clearMap();
                let promise = (resolve, reject) => {
                    switch (cateValue) {
                        case A_COLLISION_VALUE:
                            this.initCollisionEvent(startDate, endDate)
                                .then(args => {
                                    let [startDate, endDate] = args;
                                    resolve([A_COLLISION_VALUE, startDate, endDate]);
                                })
                                .catch(args => {
                                    let [startDate, endDate] = args;
                                    reject([A_COLLISION_VALUE, startDate, endDate]);
                                });
                            break;
                        case A_PARKING_MODE_COLLISION_VALUE:
                            this.initParkingCollisionEvent(startDate, endDate)
                                .then(args => {
                                    let [startDate, endDate] = args;
                                    resolve([A_PARKING_MODE_COLLISION_VALUE, startDate, endDate]);
                                })
                                .catch(args => {
                                    let [startDate, endDate] = args;
                                    reject([A_PARKING_MODE_COLLISION_VALUE, startDate, endDate]);
                                });
                            break;
                        case A_DRIVER_ALERT_VALUE:
                            this.initDriverAlertEvent(startDate, endDate)
                                .then(args => {
                                    let [startDate, endDate] = args;
                                    resolve([A_DRIVER_ALERT_VALUE, startDate, endDate]);
                                })
                                .catch(args => {
                                    let [startDate, endDate] = args;
                                    reject([A_DRIVER_ALERT_VALUE, startDate, endDate]);
                                });
                            break;
                        case A_UNPERMITTED_DRIVER_VALUE:
                            this.initUnpermittedAlertEvent(startDate, endDate)
                                .then(args => {
                                    let [startDate, endDate] = args;
                                    resolve([A_UNPERMITTED_DRIVER_VALUE, startDate, endDate]);
                                })
                                .catch(args => {
                                    let [startDate, endDate] = args;
                                    reject([A_UNPERMITTED_DRIVER_VALUE, startDate, endDate]);
                                });
                            break;
                        case A_ALL_VALUE:
                            this.initAllEvent(startDate, endDate)
                                .then(args => {
                                    let [startDate, endDate] = args;
                                    resolve([A_ALL_VALUE, startDate, endDate]);
                                })
                                .catch(args => {
                                    let [startDate, endDate] = args;
                                    reject([A_ALL_VALUE, startDate, endDate]);
                                });
                            break;
                        default:
                            reject([cateValue, startDate, endDate]);
                            break;
                    }
                };
                return new Promise(promise);
            },
            initAllEvent(startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [startDate, endDate];
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    this.getAllAlertData(startDate, endDate).then(data => {
                        if (
                            this.startDate === startDate
                            && this.endDate === endDate
                            && this.category === ALERT_VALUE
                            && this.categoryValue === A_ALL_VALUE
                        ) {
                            let {list} = data;
                            this.tripEvent.list = Object.assign([], list);
                            this.handleTripEventListForMap(list, A_ALL_VALUE);
                        }
                        resolve(params);
                    }).catch(() => {
                        console.log('Failed to get all alert list');
                        reject(params);
                    });
                };
                return new Promise(promise);
            },
            initCollisionEvent(startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [startDate, endDate];
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    this.getCollisionAlertData(startDate, endDate).then(data => {
                        if (
                            this.startDate === startDate
                            && this.endDate === endDate
                            && this.category === ALERT_VALUE
                            && this.categoryValue === A_COLLISION_VALUE
                        ) {
                            let {list} = data;
                            this.tripEvent.list = Object.assign([], list);
                            this.handleTripEventListForMap(list, A_COLLISION_VALUE);
                        }
                        resolve(params);
                    }).catch(() => {
                        console.log('Failed to get collision list');
                        reject(params);
                    });
                };
                return new Promise(promise);
            },
            initDriverAlertEvent(startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [startDate, endDate];
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    this.getDriverAlertData(startDate, endDate).then(data => {
                        if (
                            this.startDate === startDate
                            && this.endDate === endDate
                            && this.category === ALERT_VALUE
                            && this.categoryValue === A_DRIVER_ALERT_VALUE
                        ) {
                            let {list} = data;
                            this.tripEvent.list = Object.assign([], list);
                            this.handleTripEventListForMap(list, A_DRIVER_ALERT_VALUE);
                        }
                        resolve(params);
                    }).catch(() => {
                        console.log('Failed to get collision list');
                        reject(params);
                    });
                };
                return new Promise(promise);
            },
            initParkingCollisionEvent(startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [startDate, endDate];
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    this.getParkingCollisionAlertData(startDate, endDate).then(data => {
                        if (
                            this.startDate === startDate
                            && this.endDate === endDate
                            && this.category === ALERT_VALUE
                            && this.categoryValue === A_PARKING_MODE_COLLISION_VALUE
                        ) {
                            let {list} = data;
                            this.tripEvent.list = Object.assign([], list);
                            this.handleTripEventListForMap(list, A_PARKING_MODE_COLLISION_VALUE);
                        }
                        resolve(params);
                    }).catch(() => {
                        console.log('Failed to get collision list');
                        reject(params);
                    });
                };
                return new Promise(promise);
            },
            initUnpermittedAlertEvent(startDate, endDate) {
                let promise = (resolve, reject) => {
                    let params = [startDate, endDate];
                    this.openLoadingStatus(); // 打开list显示Loading提示记载信息
                    this.getUnpermittedAlertData(startDate, endDate).then(data => {
                        if (
                            this.startDate === startDate
                            && this.endDate === endDate
                            && this.category === ALERT_VALUE
                            && this.categoryValue === A_UNPERMITTED_DRIVER_VALUE
                        ) {
                            let {list} = data;
                            this.tripEvent.list = Object.assign([], list);
                            this.handleTripEventListForMap(list, A_UNPERMITTED_DRIVER_VALUE);
                        }
                        resolve(params);
                    }).catch(() => {
                        console.log('Failed to get collision list');
                        reject(params);
                    });
                };
                return new Promise(promise);
            },
            getTripDriverSummary(driverId, startDate, endDate) {
                return this.getTripSummary(startDate, endDate, driverId, null, null);
            },
            getTripVehicleSummary(vehicleId, startDate, endDate) {
                return this.getTripSummary(startDate, endDate, null, vehicleId, null);
            },
            getTripFleetSummary(fleetId, startDate, endDate) {
                return this.getTripSummary(startDate, endDate, null, null, fleetId);
            },
            getTripSummary(startDate, endDate, driverId = null, vehicleId = null, fleetId = null) {
                let key = 'trip-summary-' + startDate + '-' + endDate + '-';
                let reqParams = {startDate: startDate, endDate: endDate};
                if (driverId !== null) {
                    key += driverId + '-';
                    reqParams.driverId = driverId;
                }
                if (vehicleId !== null) {
                    key += vehicleId;
                    reqParams.vehicleId = vehicleId;
                }
                if (fleetId !== null) {
                    key += fleetId;
                    reqParams.fleetId = fleetId;
                }
                let promise = (resolve, reject) => {
                    if (this.tripSummary.apiDataMap.has(key)) {
                        resolve(this.tripSummary.apiDataMap.get(key));
                    } else {
                        this.$serverApi.trip.summary(reqParams, summaryInfo => {
                            if (this.getCurrentDate() !== reqParams.endDate)
                                this.tripSummary.apiDataMap.set(key, summaryInfo.Data);
                            resolve(summaryInfo.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getTripDriverData(driverId, startDate, endDate) {
                let promise = (resolve, reject) => {
                    let key = `trip-driver-${driverId}-${startDate}-${endDate}`;
                    if (this.tripDriver.apiDataMap.has(key)) {
                        resolve(this.tripDriver.apiDataMap.get(key));
                    } else {
                        let params = {startDate: startDate, endDate: endDate, driverId: driverId};
                        this.$serverApi.trip.driver(params, trip => {
                            if (this.getCurrentDate() !== params.endDate)
                                this.tripDriver.apiDataMap.set(key, trip.Data);
                            resolve(trip.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getTripFleetData(fleetId, startDate, endDate) {
                let promise = (resolve, reject) => {
                    let key = `trip-fleet-${fleetId}-${startDate}-${endDate}`;
                    if (this.tripFleet.apiDataMap.has(key)) {
                        resolve(this.tripFleet.apiDataMap.get(key));
                    } else {
                        let params = {startDate: startDate, endDate: endDate, fleetId: fleetId};
                        this.$serverApi.trip.fleet(params, trip => {
                            if (this.getCurrentDate() !== params.endDate)
                                this.tripFleet.apiDataMap.set(key, trip.Data);
                            resolve(trip.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getTripVehicleData(vehicleId, startDate, endDate) {
                let promise = (resolve, reject) => {
                    let key = `trip-vehicle-${vehicleId}-${startDate}-${endDate}`;
                    if (this.tripVehicle.apiDataMap.has(key)) {
                        resolve(this.tripVehicle.apiDataMap.get(key));
                    } else {
                        let params = {startDate: startDate, endDate: endDate, vehicleId: vehicleId};
                        this.$serverApi.trip.vehicle(params, trip => {
                            if (this.getCurrentDate() !== params.endDate)
                                this.tripVehicle.apiDataMap.set(key, trip.Data);
                            resolve(trip.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getAllAlertData(startDate, endDate) {
                let promise = (resolve, reject) => {
                    this.getTripEventData(A_ALL_VALUE, startDate, endDate).then(data => {
                        resolve({list: data});
                    }).catch(() => {
                        reject({});
                    });
                };
                return new Promise(promise);
            },
            getCollisionAlertData(startDate, endDate) {
                let promise = (resolve, reject) => {
                    this.getTripEventData(A_COLLISION_VALUE, startDate, endDate).then(data => {
                        resolve({list: data});
                    }).catch(() => {
                        reject({});
                    });
                };
                return new Promise(promise);
            },
            getDriverAlertData(startDate, endDate) {
                let promise = (resolve, reject) => {
                    this.getTripEventData(A_DRIVER_ALERT_VALUE, startDate, endDate).then(data => {
                        resolve({list: data});
                    }).catch(() => {
                        reject({});
                    });
                };
                return new Promise(promise);
            },
            getParkingCollisionAlertData(startDate, endDate) {
                let promise = (resolve, reject) => {
                    this.getTripEventData(A_PARKING_MODE_COLLISION_VALUE, startDate, endDate).then(data => {
                        resolve({list: data});
                    }).catch(() => {
                        reject({});
                    });
                };
                return new Promise(promise);
            },
            getUnpermittedAlertData(startDate, endDate) {
                let promise = (resolve, reject) => {
                    this.getTripEventData(A_UNPERMITTED_DRIVER_VALUE, startDate, endDate).then(data => {
                        resolve({list: data});
                    }).catch(() => {
                        reject({});
                    });
                };
                return new Promise(promise);
            },
            getTripEventData(eventType, startDate, endDate) {
                let promise = (resolve, reject) => {
                    if (!eventType || !startDate || !endDate) {
                        resolve([]);
                        return;
                    }
                    let key = `event-${eventType}-${startDate}-${endDate}-All`;
                    if (this.tripEvent.apiDataMap.has(key)) {
                        resolve(this.tripEvent.apiDataMap.get(key));
                    } else {
                        let params = {startDate: startDate, endDate: endDate, event: eventType, type: 'All'};
                        this.$serverApi.trip.event(params, data => {
                            if (this.getCurrentDate() !== params.endDate)
                                this.tripEvent.apiDataMap.set(key, data.Data);
                            resolve(data.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getParkingAlertListByFleetId(startDate, endDate, fleetId) {
                let promise = (resolve, reject) => {
                    let key = `parking-alert-ParkingCollision-fleet-${fleetId}-${startDate}-${endDate}-All`;
                    if (this.tripEvent.apiDataMap.has(key)) {
                        resolve(this.tripEvent.apiDataMap.get(key));
                    } else {
                        let params = {
                            fleetId: fleetId,
                            startDate: startDate,
                            endDate: endDate,
                            event: 'ParkingCollision',
                            type: 'All'
                        };
                        this.$serverApi.trip.event(params, data => {
                            if (this.getCurrentDate() !== params.endDate)
                                this.tripEvent.apiDataMap.set(key, data.Data);
                            resolve(data.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getParkingAlertListByVehicleId(startDate, endDate, vehicleId) {
                let promise = (resolve, reject) => {
                    let key = `parking-alert-ParkingCollision-${vehicleId}-${startDate}-${endDate}-All`;
                    if (this.tripEvent.apiDataMap.has(key)) {
                        resolve(this.tripEvent.apiDataMap.get(key));
                    } else {
                        let params = {
                            vehicleId: vehicleId,
                            startDate: startDate,
                            endDate: endDate,
                            event: 'ParkingCollision',
                            type: 'All'
                        };
                        this.$serverApi.trip.event(params, data => {
                            if (this.getCurrentDate() !== params.endDate)
                                this.tripEvent.apiDataMap.set(key, data.Data);
                            resolve(data.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getStartAndEndDateForTrip(dateStr) {
                let datePre = this.getPreMonth(dateStr);
                let dateNext = this.getNextMonth(dateStr);
                return {
                    start: datePre.last7Day,
                    end: dateNext.first14Day
                };
            },
            getNextMonth(dateStr) {
                let dateObj = this.formatDateStrToObj(dateStr);
                let year = parseInt(dateObj.year);
                let month = parseInt(dateObj.month);
                let day = parseInt(dateObj.day);
                if (month === 12) {
                    year = year + 1;
                    month = 1;
                } else {
                    month = month + 1;
                }
                let endDay = new Date(year, month, 0).getDate();
                let last7Day = endDay - 6;
                day = day > endDay ? endDay : day;
                year = '' + year;
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + month : '' + month;
                return {
                    year: year,
                    month: month,
                    endDay: endDay + '',
                    str: year + '-' + month + '-' + day,
                    start: year + '-' + month + '-01',
                    end: year + '-' + month + '-' + endDay,
                    first14Day: year + '-' + month + '-14',
                    last7Day: year + '-' + month + '-' + last7Day
                };
            },
            getPreMonth(dateStr) {
                let dateObj = this.formatDateStrToObj(dateStr);
                let year = parseInt(dateObj.year);
                let month = parseInt(dateObj.month);
                let day = parseInt(dateObj.day);
                if (month === 1) {
                    year = year - 1;
                    month = 12;
                } else {
                    month = month - 1;
                }
                let endDay = new Date(year, month, 0).getDate();
                let last7Day = endDay - 6;
                day = day > endDay ? endDay : day;
                year = '' + year;
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + month : '' + month;
                return {
                    year: year,
                    month: month,
                    endDay: endDay + '',
                    str: year + '-' + month + '-' + day,
                    start: year + '-' + month + '-01',
                    end: year + '-' + month + '-' + endDay,
                    first14Day: year + '-' + month + '-14',
                    last7Day: year + '-' + month + '-' + last7Day
                };
            },
            formatDateStrToObj(dateStr) {
                let dateArr = dateStr.split('-');
                let year = parseInt(dateArr[0]);
                let month = parseInt(dateArr[1]);
                let day = parseInt(dateArr[2]);
                let monthStr = month < 10 ? '0' + month : '' + month;
                let dayStr = day < 10 ? '0' + day : '' + day;
                return {
                    year: '' + year,
                    month: monthStr,
                    day: dayStr,
                    str: year + '-' + month + '-' + dayStr,
                    start: year + '-' + monthStr + '-01',
                    end: year + '-' + monthStr + '-' + new Date(year, month, 0).getDate()
                };
            },
            clearMap() {
                this.mapOptions.revvingUp = [];
                this.mapOptions.hardBraking = [];
                this.mapOptions.hardCornering = [];
                this.mapOptions.LDW = [];
                this.mapOptions.FCW = [];
                this.mapOptions.driverFatigue = [];
                this.mapOptions.distractedDriving = [];
                this.mapOptions.smoking = [];
                this.mapOptions.phoneUsage = [];
                this.mapOptions.historyPath = [];
                this.mapOptions.boundaryPath = [];
                this.mapOptions.startLocation = {};
                this.mapOptions.endLocation = {};
                this.mapOptions.collisionsCars = [];
                this.mapOptions.driverAlert = [];
                this.mapOptions.parkingAlert = [];
                this.mapOptions.unpermittedDriver = [];
                this.mapOptions.headquarterCall = [];
                this.mapOptions.driverCall = [];
                this.mapOptions.targetEvent = {
                    center: null,
                    eventId: null,
                    eventType: null
                };
            },
            clearCurrentTripId() {
                this.tripDriver.currTripId = null;
                this.tripVehicle.currTripId = null;
            },
            closeTripAlertBox() {
                this.alertMessageOption.show = false;
            },
            closeCollisionAlertBox() {
                this.collisionInfoAlertBoxOption.show = false;
            },
            handleTripDriverSelected(tripId, tripDriverInfo) {
                this.closeCollisionAlertBox();
                this.closeTripAlertBox();
                let reqStartDate = this.startDate,
                    reqEndDate = this.endDate;
                let reqCategoryValue = this.categoryValue;
                this.tripDriver.currTripId = tripId;
                this.tripDriver.currTripInfo = tripDriverInfo;
                let driverPromise = this.getDriverInfoByDriverId(tripDriverInfo.DriverId);
                let tripEventPromise = this.getTripEventByTripId(tripId, {
                    collisionCount: tripDriverInfo.CollisionCount,
                    driverAlertCount: tripDriverInfo.DriverAlertCount
                });
                let tripHCallPromise = this.getTripHcallListByTripId(tripId, {
                    hCallCount: tripDriverInfo.HCallCount || 0
                });
                Promise.all([driverPromise, tripEventPromise, tripHCallPromise]).then(data => {
                    if (
                        this.tripDriver.currTripId !== tripId
                        || this.category !== DRIVER_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    let driverInfo = data[0],
                        newEventInfo = [],
                        eventInfo = data[1].sort((a, b) => {
                            return (a.Timestamp - b.Timestamp);
                        });
                    let hCallList = data[2];
                    eventInfo.forEach(item => {
                        if (item.Event !== 'Unpermitted')
                            newEventInfo.push(item);
                    });
                    this.alertMessageOption.show = true;
                    this.alertMessageOption.data = tripDriverInfo;
                    this.alertMessageOption.driver = driverInfo;
                    this.alertMessageOption.eventList = collisionVideoImageKeyConversion(newEventInfo);
                    this.alertMessageOption.hCallList = this.sort(hCallList, 'Timestamp');
                }).catch(() => {
                    console.log('Failed to get driver and event infos.');
                });
                this.clearMap();
                this.getTripDetailByTripId(tripId).then(tripDetail => {
                    if (
                        this.tripDriver.currTripId !== tripId
                        || this.category !== DRIVER_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    this.handleTripDetail(tripDetail);
                }).catch(() => {
                    console.log('Failed to get trip detail.');
                });
            },
            handleTripFleetSelected(tripId, tripDriverInfo) {
                this.closeCollisionAlertBox();
                this.closeTripAlertBox();
                let reqStartDate = this.startDate,
                    reqEndDate = this.endDate;
                let reqCategoryValue = this.categoryValue;
                this.tripFleet.currTripId = tripId;
                this.tripFleet.currTripInfo = tripDriverInfo;
                let driverPromise = this.getDriverInfoByDriverId(tripDriverInfo.DriverId);
                let tripEventPromise = this.getTripEventByTripId(tripId, {
                    collisionCount: tripDriverInfo.CollisionCount,
                    driverAlertCount: tripDriverInfo.DriverAlertCount
                });
                let tripHCallPromise = this.getTripHcallListByTripId(tripId, {
                    hCallCount: tripDriverInfo.HCallCount || 0
                });
                Promise.all([driverPromise, tripEventPromise, tripHCallPromise]).then(data => {
                    if (
                        this.tripFleet.currTripId !== tripId
                        || this.category !== FLEET_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    let driverInfo = data[0],
                        newEventInfo = [],
                        eventInfo = data[1].sort((a, b) => {
                            return (a.Timestamp - b.Timestamp);
                        });
                    let hCallList = data[2];
                    eventInfo.forEach(item => {
                        if (item.Event !== 'Unpermitted')
                            newEventInfo.push(item);
                    });
                    this.alertMessageOption.show = true;
                    this.alertMessageOption.data = tripDriverInfo;
                    this.alertMessageOption.driver = driverInfo;
                    this.alertMessageOption.eventList = collisionVideoImageKeyConversion(newEventInfo);
                    this.alertMessageOption.hCallList = this.sort(hCallList, 'Timestamp');
                }).catch(() => {
                    console.log('Failed to get driver and event infos.');
                });
                this.clearMap();
                this.getTripDetailByTripId(tripId).then(tripDetail => {
                    if (
                        this.tripFleet.currTripId !== tripId
                        || this.category !== FLEET_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    this.handleTripDetail(tripDetail);
                }).catch(() => {
                    console.log('Failed to get trip detail.');
                });
            },
            handleTripVehicleSelected(tripId, tripDriverInfo) {
                this.closeCollisionAlertBox();
                this.closeTripAlertBox();
                let reqStartDate = this.startDate,
                    reqEndDate = this.endDate;
                let reqCategoryValue = this.categoryValue;
                this.tripVehicle.currTripId = tripId;
                this.tripVehicle.currTripInfo = tripDriverInfo;
                let driverPromise = this.getDriverInfoByDriverId(tripDriverInfo.DriverId);
                let tripEventPromise = this.getTripEventByTripId(tripId, {
                    collisionCount: tripDriverInfo.CollisionCount,
                    driverAlertCount: tripDriverInfo.DriverAlertCount
                });
                let tripHCallPromise = this.getTripHcallListByTripId(tripId, {
                    hCallCount: tripDriverInfo.HCallCount || 0
                });
                Promise.all([driverPromise, tripEventPromise, tripHCallPromise]).then(data => {
                    if (
                        this.tripVehicle.currTripId !== tripId
                        || this.category !== VEHICLE_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    let driverInfo = data[0],
                        newEventInfo = [],
                        eventInfo = data[1].sort((a, b) => {
                            return (a.Timestamp - b.Timestamp);
                        });
                    let hCallList = data[2];
                    eventInfo.forEach(item => {
                        if (item.Event !== 'Unpermitted')
                            newEventInfo.push(item);
                    });
                    this.alertMessageOption.show = true;
                    this.alertMessageOption.data = tripDriverInfo;
                    this.alertMessageOption.driver = driverInfo;
                    this.alertMessageOption.eventList = collisionVideoImageKeyConversion(newEventInfo);
                    this.alertMessageOption.hCallList = this.sort(hCallList, 'Timestamp');
                }).catch(() => {
                    console.log('Failed to get driver and event infos.');
                });
                this.clearMap();
                this.getTripDetailByTripId(tripId).then(tripDetail => {
                    if (
                        this.tripVehicle.currTripId !== tripId
                        || this.category !== VEHICLE_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    this.handleTripDetail(tripDetail);
                }).catch(() => {
                    console.log('Failed to get trip detail.');
                });
            },
            handleTripDetail(tripDetail) {
                this.tripDetail.currInfo = tripDetail;
                this.clearMap();
                let length = tripDetail.length;
                if (length === 0) return;
                let {
                    collisionFlag, accelerationFlag, brakingFlag,
                    corneringFlag, LDWFlag, FCWFlag, fatigueFlag,
                    distractedFlag, smokingFlag, phoningFlag, historyPath,
                    center, startLocation, endLocation, driverAlertFlag,
                    dCallFlag, hCallFlag
                } = this.getMapIconsFromTripDetail(tripDetail);
                if (center) this.mapOptions.center = center;
                if (startLocation) this.mapOptions.startLocation = startLocation;
                if (endLocation) this.mapOptions.endLocation = endLocation;
                this.mapOptions.revvingUp = accelerationFlag;
                this.mapOptions.hardBraking = brakingFlag;
                this.mapOptions.hardCornering = corneringFlag;
                this.mapOptions.LDW = LDWFlag;
                this.mapOptions.FCW = FCWFlag;
                this.mapOptions.driverFatigue = fatigueFlag;
                this.mapOptions.distractedDriving = distractedFlag;
                this.mapOptions.smoking = smokingFlag;
                this.mapOptions.phoneUsage = phoningFlag;
                this.mapOptions.collisionsCars = collisionFlag;
                this.mapOptions.driverAlert = driverAlertFlag;
                this.mapOptions.historyPath = historyPath;
                this.mapOptions.driverCall = dCallFlag;
                this.mapOptions.headquarterCall = hCallFlag;
            },
            getFleetList() {
                let promise = (resolve, reject) => {
                    if (this.apiFleetList.Id) {
                        setTimeout(() => {
                            resolve(this.apiFleetList);
                        }, 0);
                    } else if (this.apiFleetListAjaxStatus) {
                        reject({});
                    } else {
                        this.apiFleetListAjaxStatus = true;
                        this.$serverApi.fleet.getFleetData(null, data => {
                            this.apiFleetListAjaxStatus = false;
                            this.apiFleetList = data;
                            bus.$emit('trip-history-filter-condition', {type: API_FLEET_DATA, data: data});
                            resolve(data);
                        }, (error) => {
                            this.apiFleetListAjaxStatus = false;
                            reject(error);
                        });
                    }
                };
                return new Promise(promise);
            },
            getVehicleList() {
                let promise = (resolve, reject) => {
                    if (this.apiVehicleList.length) {
                        resolve(this.apiVehicleList);
                    } else if (this.apiVehicleListAjaxStatus) {
                        reject({});
                    } else {
                        this.apiVehicleListAjaxStatus = true;
                        this.$serverApi.vehicles.vehicleList(data => {
                            this.apiVehicleListAjaxStatus = false;
                            this.apiVehicleList = data;
                            bus.$emit('trip-history-filter-condition', {type: API_VEHICLE_DATA, data: data});
                            resolve(data);
                        }, (error) => {
                            this.apiVehicleListAjaxStatus = false;
                            reject(error);
                        });
                    }
                };
                return new Promise(promise);
            },
            getDriverList() {
                let promise = (resolve, reject) => {
                    if (this.apiDriverList.length) {
                        resolve(this.apiDriverList);
                    } else if (this.apiDriverListAjaxStatus) {
                        reject({})
                    } else {
                        this.apiDriverListAjaxStatus = true;
                        this.$serverApi.driver.driverList((data) => {
                            this.apiDriverListAjaxStatus = false;
                            this.apiDriverList = data;
                            bus.$emit('trip-history-filter-condition', {type: API_DRIVER_DATA, data: data});
                            resolve(data);
                        }, (error) => {
                            this.apiDriverListAjaxStatus = false;
                            reject(error);
                        });
                    }
                };
                return new Promise(promise);
            },
            getDriverInfoByDriverId(driverId) {
                let promise = (resolve, reject) => {
                    this.getDriverList().then(driverList => {
                        let driverInfo = {};
                        driverList.forEach(driver => {
                            if (driver.Id === driverId) driverInfo = driver;
                        });
                        resolve(driverInfo);
                    }).catch(() => {
                        reject();
                    });
                };
                return new Promise(promise);
            },
            getTripEventByTripId(tripId, tripInfo) {
                let key = `event-trip-id-${tripId}-All`;
                let {collisionCount, driverAlertCount} = tripInfo;
                let promise = (resolve, reject) => {
                    if (!collisionCount && !driverAlertCount) {
                        resolve([]);
                        return;
                    }
                    if (this.tripEvent.apiDataMap.has(key)) {
                        resolve(this.tripEvent.apiDataMap.get(key));
                    } else {
                        this.$serverApi.trip.eventTripId({
                            "type": 'All',
                            "tripId": tripId
                        }, data => {
                            this.tripEvent.apiDataMap.set(key, data.Data);
                            resolve(data.Data);
                        }, () => {
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getTripDetailByTripId(tripId) {
                let promise = (resolve, reject) => {
                    if (this.tripDetail.apiDataMap.has(tripId)) {
                        this.tripDetail.currInfo = this.tripDetail.apiDataMap.get(tripId)
                        resolve(this.tripDetail.currInfo);
                    } else {
                        this.$serverApi.trip.detail(tripId, (data) => {
                            let result = [];
                            if (data && data.GPS && data.GPS.length > 0) {
                                result = data.GPS;
                                this.tripDetail.apiDataMap.set(tripId, result);
                            }
                            this.tripDetail.currInfo = result;
                            resolve(result);
                        }, () => {
                            this.tripDetail.currInfo = null;
                            reject({});
                        });
                    }
                };
                return new Promise(promise);
            },
            getTripHcallListByTripId(tripId, count) {
                let {hCallCount} = count;
                let promise = (resolve, reject) => {
                    if (!hCallCount) {
                        resolve([]);
                        return;
                    }
                    if (this.tripHcall.apiDataMap.has(tripId)) {
                        this.tripHcall.currInfo = this.tripHcall.apiDataMap.get(tripId);
                        resolve(this.tripHcall.currInfo);
                    } else {
                        this.$serverApi.trip.hcall({tripId: tripId}, data => {
                            this.tripHcall.apiDataMap.set(tripId, data);
                            this.tripHcall.currInfo = data;
                            resolve(data);
                        }, (error) => {
                            reject(error);
                        });
                    }
                };
                return new Promise(promise);
            },
            getFleetInfoByFleetId(fleetId) {
                let promise = (resolve, reject) => {
                    this.getFleetList().then(fleetList => {
                        let fleetInfo = {};
                        if (fleetList.Sub) {
                            fleetList.Sub.forEach(fleet => {
                                if (fleet.Id === fleetId)
                                    fleetInfo = fleet;
                            });
                        }
                        resolve(fleetInfo);
                    }).catch(() => {
                        reject();
                    })
                };
                return new Promise(promise);
            },
            getVehicleInfoByVehicleId(vehicleId) {
                let promise = (resolve, reject) => {
                    this.getVehicleList().then(vehicleList => {
                        let vehicleInfo = {};
                        vehicleList.forEach(item => {
                            if (item.Id === vehicleId)
                                vehicleInfo = item;
                        });
                        resolve(vehicleInfo);
                    }).catch(() => {
                        reject();
                    });
                };
                return new Promise(promise);
            },
            handleFilterDriverTripFlagRules(flagRules) {
                this.mapOptions.filterDriverTripRules = flagRules.rules;
                if (flagRules.redrawMap && this.tripDetail.currInfo) {
                    let tripDetail = this.tripDetail.currInfo;
                    let length = tripDetail.length;
                    if (length === 0) {
                        this.clearMap();
                        return;
                    }
                    let {
                        collisionFlag, accelerationFlag, brakingFlag,
                        corneringFlag, LDWFlag, FCWFlag, fatigueFlag,
                        distractedFlag, smokingFlag, phoningFlag,
                        driverAlertFlag, dCallFlag, hCallFlag
                    } = this.getMapIconsFromTripDetail(tripDetail);
                    this.mapOptions.revvingUp = accelerationFlag;
                    this.mapOptions.hardBraking = brakingFlag;
                    this.mapOptions.hardCornering = corneringFlag;
                    this.mapOptions.LDW = LDWFlag;
                    this.mapOptions.FCW = FCWFlag;
                    this.mapOptions.driverFatigue = fatigueFlag;
                    this.mapOptions.distractedDriving = distractedFlag;
                    this.mapOptions.smoking = smokingFlag;
                    this.mapOptions.phoneUsage = phoningFlag;
                    this.mapOptions.collisionsCars = collisionFlag;
                    this.mapOptions.driverAlert = driverAlertFlag;
                    this.mapOptions.driverCall = dCallFlag;
                    this.mapOptions.headquarterCall = hCallFlag;
                }
            },
            getMapIconsFromTripDetail(tripDetail) {
                let collisionFlag = [],
                    driverAlertFlag = [],
                    accelerationFlag = [],
                    brakingFlag = [],
                    corneringFlag = [],
                    LDWFlag = [],
                    FCWFlag = [],
                    fatigueFlag = [],//疲劳
                    distractedFlag = [],//分心
                    smokingFlag = [],//抽烟
                    phoningFlag = [],//打电话
                    historyPath = [],
                    hCallFlag = [],
                    dCallFlag = [],
                    center = null,
                    startLocation = null,
                    startData = null,
                    endLocation = null,
                    endData = null;
                if (tripDetail.forEach) {
                    let filterRules = {};
                    this.mapOptions.filterDriverTripRules.forEach(filedName => filterRules[filedName] = true);
                    tripDetail.forEach((item) => {
                        let timeStr = this.$dateFormat.dateTime(item.Timestamp, this.mixin_dateTimeFormat, this.mixin_timezone);
                        let address = this.getAddress(item.Latitude, item.Longitude);
                        let gps = this.getGps(item.Latitude, item.Longitude);
                        if ((item.Speed === -1 || !item.Speed) && item.Speed !== 0) {
                            item.Speed = this.$t('globalText.na');
                        }
                        let tmpSpeed = this.$unit.convertKilometerUnit(item.Speed);
                        let speedStr = numRound(tmpSpeed, 1);
                        if (item.Longitude === 0 && item.Latitude === 0) {

                        } else {
                            if (startData === null) startData = item;
                            if (startLocation === null) startLocation = {lng: item.Longitude, lat: item.Latitude};
                            center = {lng: item.Longitude, lat: item.Latitude};
                            endLocation = {lng: item.Longitude, lat: item.Latitude};
                            endData = item;
                            if (item.AccelerationFlag >= 1 && filterRules.AccelerationFlag) {
                                accelerationFlag.push({
                                    name: this.$t('tripHistory.hardRevving'), // 'Hard Revving',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.AccelerationFlag,
                                    speedUnit: this.unit4Speed
                                });
                            }
                            if (item.BrakingFlag >= 1 && filterRules.BrakingFlag) {
                                brakingFlag.push({
                                    name: this.$t('tripHistory.hardBraking'), // 'Hard Braking',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.BrakingFlag,
                                    speedUnit: this.unit4Speed
                                });
                            }
                            if (item.CorneringFlag >= 1 && filterRules.CorneringFlag) {
                                corneringFlag.push({
                                    name: this.$t('tripHistory.hardCornering'), // 'Hard Cornering',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.CorneringFlag,
                                    speedUnit: this.unit4Speed
                                });
                            }
                            if (item.LDWFlag >= 1 && filterRules.LDWFlag) {
                                LDWFlag.push({
                                    name: this.$t('tripHistory.LDW'), // 'LDW',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.LDWFlag,
                                    speedUnit: this.unit4Speed,
                                    picture:item.LDWPictures || item.LDWPicture || []
                                });
                            }
                            if (item.FCWFlag >= 1 && filterRules.FCWFlag) {
                                FCWFlag.push({
                                    name: this.$t('tripHistory.FCW'), // 'FCW',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.FCWFlag,
                                    speedUnit: this.unit4Speed,
                                    picture:item.FCWPictures || item.FCWPicture || []
                                });
                            }
                            if (item.FatigueFlag >= 1 && filterRules.FatigueFlag) {
                                fatigueFlag.push({
                                    name: this.$t('tripHistory.driverFatigue'), // 'Driver Fatigue',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.FatigueFlag,
                                    speedUnit: this.unit4Speed,
                                    picture: item.FatiguePictures || item.FatiguePicture || []
                                });
                            }
                            if (item.DistractedFlag >= 1 && filterRules.DistractedFlag) {
                                distractedFlag.push({
                                    name: this.$t('tripHistory.distractedDriving'), // 'Distracted Driving',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.DistractedFlag,
                                    speedUnit: this.unit4Speed,
                                    picture: item.DistractedPictures || item.DistractedPicture || []
                                    // picture: ['http://dev.video.mobile360.viatech.com/FatiguePicture/F6DE80CA668DA458C04637DB56302F3D@1607329836.jpg','http://dev.video.mobile360.viatech.com/FatiguePicture/F6DE80CA668DA458C04637DB56302F3D@1607329836.jpg']
                                });
                            }
                            if (item.SmokingFlag >= 1 && filterRules.SmokingFlag) {
                                smokingFlag.push({
                                    name: this.$t('tripHistory.smoking'), // 'Smoking',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.SmokingFlag,
                                    speedUnit: this.unit4Speed,
                                    picture: item.SmokingPictures || item.SmokingPicture || []
                                });
                            }
                            if (item.PhoneFlag >= 1 && filterRules.PhoneFlag) {
                                phoningFlag.push({
                                    name: this.$t('tripHistory.phoneUsage'), // 'Phone Usage',
                                    iconName: 'via-flag',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.PhoneFlag,
                                    speedUnit: this.unit4Speed,
                                    picture: item.PhonePictures || item.PhonePicture || []
                                });
                            }
                            if (item.CollisionFlag >= 1 && filterRules.CollisionFlag) {
                                collisionFlag.push({
                                    name: this.$t('tripHistory.collisionAlert'), // 'Collision Alert',
                                    iconName: 'via-car-crash',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    eventId: item.EventId,
                                    count: item.CollisionFlag,
                                    speedUnit: this.unit4Speed
                                });
                            }
                            if (item.DriverAlertFlag >= 1 && filterRules.DriverAlertFlag) {
                                driverAlertFlag.push({
                                    name: this.$t('tripHistory.driverAlert'), // 'Driver Alert',
                                    iconName: 'via-Driver-alert',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    eventId: item.EventId,
                                    count: item.DriverAlertFlag,
                                    speedUnit: this.unit4Speed
                                });
                            }
                            if (item.DCallFlag >= 1 && filterRules.DCallFlag) {
                                dCallFlag.push({
                                    name: this.$t('tripHistory.way2CallFromDriver'), // '2-Way Call from Driver',
                                    iconName: 'via-microphone',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.DCallFlag,
                                    eventId: item.EventId,
                                    callId: item.CallId,
                                    speedUnit: this.unit4Speed,
                                    eventType: 'hCall'
                                });
                            }
                            if (item.HCallFlag >= 1 && filterRules.HCallFlag) {
                                hCallFlag.push({
                                    name:  this.$t('tripHistory.way2CallFromHeadquarter'), // '2-Way Call from Headquarter',
                                    iconName: 'via-microphone',
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.Id,
                                    time: timeStr,
                                    timestamp: item.Timestamp,
                                    speed: speedStr,
                                    address: address,
                                    gps: gps,
                                    count: item.HCallFlag,
                                    eventId: item.EventId,
                                    callId: item.CallId,
                                    speedUnit: this.unit4Speed,
                                    eventType: 'hCall'
                                });
                            }
                            historyPath.push({lng: item.Longitude, lat: item.Latitude});
                        }
                    });
                }

                return {
                    collisionFlag, accelerationFlag, brakingFlag,
                    corneringFlag, LDWFlag, FCWFlag, fatigueFlag,
                    distractedFlag, smokingFlag, phoningFlag, historyPath,
                    center, startLocation, startData, endLocation, endData,
                    driverAlertFlag, dCallFlag, hCallFlag
                };
            },
            handleAlertCollisionSelected(collisionInfo) {
                this.closeCollisionAlertBox();
                this.closeTripAlertBox();
                this.tripEvent.currInfo = collisionInfo;
                let {show, data, redrawMap} = collisionInfo || {};
                let eventType = '';
                this.mapOptions.startLocation = {};
                this.mapOptions.endLocation = {};
                this.mapOptions.historyPath = [];
                if (collisionInfo === null) return;
                switch (data.Event) {
                    case "Collision":
                        eventType = A_COLLISION_VALUE;
                        break;
                    case "DriverAlert":
                        eventType = A_DRIVER_ALERT_VALUE;
                        break;
                    case "ParkingCollision":
                        eventType = A_PARKING_MODE_COLLISION_VALUE;
                        break;
                    case "Unpermitted":
                        this.handleTripDetailForUnpermittedDriver(data.TripId, false);
                        eventType = A_UNPERMITTED_DRIVER_VALUE;
                        break;
                }
                let driverPromise = this.getDriverInfoByDriverId(data.DriverId);
                let fleetInfoPromise = this.getFleetInfoByFleetId(data.FleetId);
                let vehicleInfoPromise = this.getVehicleInfoByVehicleId(data.VehicleId);
                Promise.all([driverPromise, fleetInfoPromise, vehicleInfoPromise]).then(dfvData => {
                    let [driverInfo, fleetInfo, vehicleInfo] = dfvData;
                    if (!vehicleInfo.Id) vehicleInfo.Id = data.VehicleId;
                    this.collisionInfoAlertBoxOption.show = show;

                    this.collisionInfoAlertBoxOption.data = collisionVideoImageKeyConversion(data);

                    this.collisionInfoAlertBoxOption.driver = driverInfo;
                    this.collisionInfoAlertBoxOption.fleet = fleetInfo;
                    this.collisionInfoAlertBoxOption.vehicle = vehicleInfo;
                    if (data && data.Latitude && data.Latitude !== 0 && data.Longitude && data.Longitude !== 0) {
                        if (redrawMap) {
                            this.mapOptions.zoomCenter = {
                                value: 18,
                                center: {lat: data.Latitude, lng: data.Longitude},
                            };
                            this.mapOptions.targetEvent = {
                                center: {lat: data.Latitude, lng: data.Longitude},
                                eventId: data.EventId,
                                eventType: eventType
                            };
                        }
                    } else {
                        this.mapOptions.targetEvent = {center: null, eventId: null, eventType: null};
                        this.mapOptions.boundaryPath = this.mapOptions.boundaryPathTmpData;
                    }
                }).catch(() => {
                    console.log('Failed to get fleet/vehicle/driver info by id');
                });
            },
            handleAlertCollisionSelectedFromVehicleList(collisionInfo) {
                this.closeCollisionAlertBox();
                this.closeTripAlertBox();
                this.tripEvent.currInfo = collisionInfo;
                let {show, data} = collisionInfo;
                this.tripVehicle.currTripId = data.EventId;
                let eventType = '';
                this.mapOptions.startLocation = {};
                this.mapOptions.endLocation = {};
                this.mapOptions.historyPath = [];
                switch (data.Event) {
                    case "Collision":
                        eventType = A_COLLISION_VALUE;
                        break;
                    case "DriverAlert":
                        eventType = A_DRIVER_ALERT_VALUE;
                        break;
                    case "ParkingCollision":
                        eventType = A_PARKING_MODE_COLLISION_VALUE;
                        break;
                    case "Unpermitted":
                        this.handleTripDetailForUnpermittedDriver(data.TripId, false);
                        eventType = A_UNPERMITTED_DRIVER_VALUE;
                        break;
                }
                let driverPromise = this.getDriverInfoByDriverId(data.DriverId);
                let fleetInfoPromise = this.getFleetInfoByFleetId(data.FleetId);
                let vehicleInfoPromise = this.getVehicleInfoByVehicleId(data.VehicleId);
                Promise.all([driverPromise, fleetInfoPromise, vehicleInfoPromise]).then(dfvData => {
                    let [driverInfo, fleetInfo, vehicleInfo] = dfvData;
                    if (!vehicleInfo.Id) vehicleInfo.Id = data.VehicleId;
                    this.collisionInfoAlertBoxOption.show = show;
                    this.collisionInfoAlertBoxOption.data = data;
                    this.collisionInfoAlertBoxOption.driver = driverInfo;
                    this.collisionInfoAlertBoxOption.fleet = fleetInfo;
                    this.collisionInfoAlertBoxOption.vehicle = vehicleInfo;
                    this.clearMap();
                    if (data && data.Latitude && data.Latitude !== 0 && data.Longitude && data.Longitude !== 0) {
                        this.mapOptions.zoomCenter = {
                            value: 18,
                            center: {lat: data.Latitude, lng: data.Longitude},
                        };
                        this.handleTripEventListForMap([data], eventType);
                        this.mapOptions.targetEvent = {
                            center: {lat: data.Latitude, lng: data.Longitude},
                            eventId: data.EventId,
                            eventType: eventType
                        };
                    } else {
                        this.mapOptions.targetEvent = {center: null, eventId: null, eventType: null};
                        this.mapOptions.boundaryPath = this.mapOptions.boundaryPathTmpData;
                    }
                }).catch(() => {
                    console.log('Failed to get fleet/vehicle/driver info by id');
                });
            },
            handleTripDetailForUnpermittedDriver(tripId, fitBounds = true) {
                let reqStartDate = this.startDate,
                    reqEndDate = this.endDate;
                let reqCategoryValue = this.categoryValue;
                this.collisionInfoAlertBoxOption.endLatitude = null;
                this.collisionInfoAlertBoxOption.endLongitude = null;
                this.collisionInfoAlertBoxOption.endTimestamp = null;
                this.collisionInfoAlertBoxOption.driverPicturePath = '';
                this.getTripDetailByTripId(tripId).then(tripDetail => {
                    let {data} = this.tripEvent.currInfo;
                    if (
                        data.TripId !== tripId
                        || this.category !== ALERT_VALUE
                        || this.startDate !== reqStartDate
                        || this.endDate !== reqEndDate
                        || this.categoryValue !== reqCategoryValue
                    ) return;
                    let length = tripDetail.length;
                    if (length === 0) return;
                    let {
                        historyPath, endLocation, endData
                    } = this.getMapIconsFromTripDetail(tripDetail);
                    if (endLocation) this.mapOptions.endLocation = endLocation;
                    this.collisionInfoAlertBoxOption.endLatitude = endData.Latitude;
                    this.collisionInfoAlertBoxOption.endLongitude = endData.Longitude;
                    this.collisionInfoAlertBoxOption.endTimestamp = endData.Timestamp;
                    this.collisionInfoAlertBoxOption.driverPicturePath = endData.DriverPicturePath || '';
                    this.mapOptions.historyPath = {data: historyPath, fitBounds: fitBounds};
                }).catch(() => {
                    console.log('Failed to get trip detail.');
                });
            },
            handleTripEventListForMap(collisionList, alertType) {
                let length = collisionList.length;
                if (length === 0) return;
                let collisionCars = [],
                    driverAlert = [],
                    parkingAlert = [],
                    unpermittedDriver = [],
                    boundaryPath = [];
                collisionList.forEach((item, index) => {
                    let timeStr = this.$dateFormat.dateTime(item.Timestamp, this.mixin_dateTimeFormat, this.mixin_timezone);
                    let address = this.getAddress(item.Latitude, item.Longitude);
                    let gps = this.getGps(item.Latitude, item.Longitude);
                    if ((item.Speed === -1 || !item.Speed) && item.Speed !== 0) {
                        item.Speed = this.$t('globalText.na');
                    }
                    let tmpSpeed = this.$unit.convertKilometerUnit(item.Speed);
                    let speedStr = item.Speed >= 0 ? numRound(tmpSpeed, 1) : this.$t('globalText.na');
                    if (item.Longitude === 0 && item.Latitude === 0) {

                    } else {
                        this.mapOptions.center = {lng: item.Longitude, lat: item.Latitude};
                        let eventInfo = {
                            lng: item.Longitude,
                            lat: item.Latitude,
                            carId: item.Id,
                            time: timeStr,
                            timestamp: item.Timestamp,
                            address: address,
                            gps: gps,
                            eventId: item.EventId,
                            eventType: alertType,
                            speedUnit: this.unit4Speed
                        };
                        switch (item.Event) {
                            case 'Collision':
                                eventInfo.name = this.$t('tripHistory.collisionAlert'); // 'Collision Alert';
                                eventInfo.iconName = 'via-car-crash';
                                eventInfo.speed = speedStr;
                                collisionCars.push(eventInfo);
                                break;
                            case 'DriverAlert':
                                eventInfo.name = this.$t('tripHistory.driverAlert'); // 'Driver Alert';
                                eventInfo.iconName = 'via-Driver-alert';
                                eventInfo.speed = speedStr;
                                driverAlert.push(eventInfo);
                                break;
                            case 'ParkingCollision':
                                eventInfo.name = this.$t('tripHistory.parkingAlert'); // 'Parking Alert';
                                eventInfo.iconName = 'via-Parking-alert';
                                parkingAlert.push(eventInfo);
                                break;
                            case 'Unpermitted':
                                eventInfo.name = this.$t('tripHistory.unpermittedDriver'); // 'Unpermitted Driver';
                                eventInfo.iconName = 'via-Unpermitted';
                                unpermittedDriver.push(eventInfo);
                                break;
                        }
                        boundaryPath.push({lng: item.Longitude, lat: item.Latitude});
                    }
                });
                this.mapOptions.collisionsCars = collisionCars;
                this.mapOptions.driverAlert = driverAlert;
                this.mapOptions.parkingAlert = parkingAlert;
                this.mapOptions.unpermittedDriver = unpermittedDriver;
                this.mapOptions.boundaryPathTmpData = boundaryPath;
            },
            handleMapEvent(event) {
                switch (this.category) {
                    case FLEET_VALUE:
                    case DRIVER_VALUE:
                    case VEHICLE_VALUE:
                        this.alertMessageOption.currAlertEvent = event;
                        break;
                    case ALERT_VALUE:
                        this.tripEvent.eventFromMap = event;
                        break;
                    default:
                        break;
                }
            },
            getAddress(latitude, longitude) {
                // map中也用到这个方法了，调用api获取具体地址的时候需要注意
                if (latitude === 0 && longitude === 0) return this.$t('globalText.na');
                return 'GPS: ' + latitude + ', ' + longitude;
            },
            getGps(latitude, longitude) {
                return {latitude: latitude, longitude: longitude};
            },
            formatDate(date) {
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate();
                month = month > 9 ? month : '0' + month;
                day = day > 9 ? day : '0' + day;
                return `${year}-${month}-${day}`;
            },
            getCurrentDate() {
                let todayTimestamp = parseInt(new Date().getTime() / 1000);
                return this.$dateFormat.date(todayTimestamp, 'YYYY-MM-DD', this.mixin_timezone);
            },
            sort(list, key, order = 'asc') {
                return list.sort((a, b) => {
                    if (a[key] === null) return 1;
                    if (b[key] === null) return -1;
                    if (order === 'asc') {
                        return a[key] - b[key];
                    } else {
                        return b[key] - a[key];
                    }
                });
            }
        }
    }
</script>

<style lang="stylus" scoped>

    .history-main-layout
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 650px;
        margin-top: -8px;

        >>> .ry-map-box
            bottom 0

        >>> .ry-middle
            bottom 7px
</style>
